import React from 'react'
import { Logo, LogoDark } from './Logo'

export function Footer({ date, year, title, description }) {
  return (
    <div className="animus-vfx-menu">
      <div>
        <Logo style={{ width: 60, height: 60 }} color="#b0b0b0" />
      </div>
      <div>
        <span>
          <b className="brand"><span>𝝠NIMUS</span> Bytes Ltd</b>
        </span>
        <a href="mailto:info@animus.email">info@animus.email</a>
        <a href="tel:+44 33 3335 5671">+44 33 3335 5671</a>
      </div>
      <div>
        <span>{date}</span>
        <span className="date">{year}</span>
      </div>
      <div className="description">
        <b>{title}</b>
        <span>{description}</span>
      </div>
      <div style={{ width: '100%' }} />
    </div>
  )
}


  export function FooterDark({ date, year, title, description }) {
    return (
      <div className="animus-vfx-menu-dark">
        <div>
          <Logo style={{ width: 60, height: 60 }} color="#b0b0b0" />
        </div>
        <div>
          <span>
            <b className="brand"><span>𝝠NIMUS</span> Bytes Ltd</b>
          </span>
          <a href="mailto:info@animus.email">info@animus.email</a>
          <a href="tel:+44 33 3335 5671">+44 33 3335 5671</a>
        </div>
        <div>
          <span>{date}</span>
          <span className="date">{year}</span>
        </div>
        <div className="description">
          <b>{title}</b>
          <span>{description}</span>
        </div>
        <div style={{ width: '100%' }} />
      </div>
    )
  }
