import React, { Component } from "react";
import Lifecycle from './Lifecycle';
import Slide from 'react-reveal/Slide';

class SoftwareProduct extends Component{
  render(){
    return(
      <React.Fragment>
        {/* <div className="section fp-section fp-tabble">
          <div className="slider_content">
              <div className="container">
                <div className="row d-flex flex-wrap align-items-center justify-content-center">
                  <div className="col-lg-6 col-sm-12 align-middle section-title order-2 order-lg-2">
                    <h2 style={{color: '#ffffff'}} className="text-center text-lg-left text-md-left">Software Product Development</h2>
                  </div>
                    <div className="col-lg-6 col-sm-12 align-middle order-1 order-lg-1">
                        <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/software-product-head.png" className="w-100" alt="Custom Web App"/>
                    </div>
                </div>
              </div>
            </div>
          </div> */}
            {/* End Breadcrump Area */}
            <div className="section fp-section fp-tabble" data-black-overlay="7">
              <div className="container slider_content">
            <div className="row d-flex flex-wrap align-items-center">
                <div className="col-lg-6 col-sm-12">
                    <div className="details mt_md--30 mt_sm--30" style={{borderRadius: '10px'}}>
                      <div className="section-title">
                      <h2 className="title">Your Product. Developed.</h2>
                      <p>We help turn your ideas into reality. Our elite team of business analysts, project managers, and engineers will guide you through the entire process of building a software product. We have substantial experience building custom software solutions and deep understanding of how businesses work, making us your perfect development partner.</p>
                      <p>We work hard to understand your current business processes and determine how software could make them more efficient. Our team always wants to produce top-quality solutions and we will create a proprietary software product that creates real value for your business.</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="mt_sm--50 align-middle">
                        <img className="align-middle" style={{maxHeight: '80vh'}} src="https://animusdevelopment.b-cdn.net/images/softwareproduct/software-product-intro.png" alt="Software Product Intro"/>
                    </div>
                </div>
            </div>
          </div>
        </div>
            <div className="section fp-section fp-tabble pbt--120" data-black-overlay="7">
              <div className="container slider_content">
              <div className="row d-flex flex-wrap align-items-center">
                <div className="col-lg-6 col-12 order-2 order-lg-1">
                  <div>
                      <Lifecycle />
                  </div>
                </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                      <div className="details mt_md--30 mt_sm--100">
                        <div className="section-title">
                        <h2 className="title">Development Lifecycle</h2>
                          <p className="customsoftware_list" style={{borderRadius: '10px'}}>We are a technology-agnostic company. In other words, we don't sell or promote specific technologies, so we're 100% focused on our customers. We pick the right stack for your unique business needs and goals.</p>
                          <p className="customsoftware_list" style={{borderRadius: '10px'}}>We are your trusted development partner not only while creating a product, but well after it's released. We offer product support and enhancement at whatever level you need, and most of our customers become our continued partners.</p>
                          <p className="customsoftware_list" style={{borderRadius: '10px'}}>From idea to prototype to release, we can help your company through the entire life cycle of new product development. We've built hundreds of products at unbeatable value, and our expertise and passion for building creative and innovative solutions is unparalleled.</p>
                      </div>
                    </div>
                  </div>
              </div>
                </div>
              </div>
          </React.Fragment>
        )
      }
    }
    export default SoftwareProduct;
