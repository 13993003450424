import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import ReactCardCarousel from "react-card-carousel";
import TechnologyIcons from './TechnologyIcons';

class Tech extends Component{
    render(){
        return(
            <React.Fragment>
              <div className="section fp-tabble fp-section slider_container">
                <div className="slider_content">
                  <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title pb--50">
                      <h2 style={{color: '#ffffff'}} className="title text-left">Technology</h2>
                      <p style={{color: '#d7d7d7', fontSize: '1.2em', lineHeight: '1.5em', paddingBottom: '10px'}}>Connecting your vision with results we focus on depth, not breadth. We equip teams with qualified experts proficient in the skills you need to help you achieve your technology vision and scale your software solutions. See the technology we use to bring your vision to life. </p>
                    </div>
                    <div className="col-lg-12 text-left main-buttons">
                    <Link className="btn-view" style={{zIndex: '999'}} to="/technology">Technology detail</Link>
                    </div>
                  </div>
                </div>
                  <div className="col-lg-12 justify-content-center d-md-none d-lg-block">
                    <TechnologyIcons />
                  </div>
                </div>
              </div>
              </div>
            </React.Fragment>
        )
    }
}
export default Tech;
