import React, { useContext, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, ContactShadows, Sphere } from '@react-three/drei'
import { a, useSpring } from '@react-spring/three'
import { useGesture } from 'react-use-gesture'

import {
  mdiLanguagePython,
  mdiReact,
  mdiLanguageJavascript,
  mdiDocker,
  mdiNodejs,
  mdiGatsby,

} from '@mdi/js'
import Icon3D from './Icon3D'

// You can use any icon from @mdi/js:
const icons = [
  { path: mdiLanguagePython, color: '#4B8BBE' },
  { path: mdiLanguageJavascript, color: '#F0DB4F' },
  { path: mdiReact, color: '#61dafb' },
  { path: mdiDocker, color: '#0db7ed' },
  { path: mdiNodejs, color: '#68A063' },
  { path: mdiGatsby, color: 'rebeccapurple' },
]

// Slowly spin around
function Turntable(props) {
  const ref = useRef()
  useFrame((_, delta) => (ref.current.rotation.y += delta))
  return <group ref={ref} {...props} />
}

// Float up and down
function Float({ speed = 1, amplitude = 1, ...props }) {
  const ref = useRef()
  useFrame(({ clock }) => (ref.current.position.y = amplitude * Math.sin(clock.elapsedTime * speed)))
  return <group ref={ref} {...props} />
}

function AnimatedIcon({ path, color, invert = false, ...props }) {
  const [hover, set] = useState(false)
  const [spins, setSpins] = useState(0)
  const spring = useSpring({ rotation: [0, spins * Math.PI, 0] })
  const canSpin = useRef(false)
  useGesture({
    onMove: ({ vxvy: [vx] }) => {
      if (canSpin.current && vx !== 0) {
        setSpins(spins + Math.sign(vx))
        canSpin.current = false
      }
    },
  })
  return (
    <a.group {...spring} {...props} ref={canSpin} onPointerOver={() => setSpins(true)} onPointerOut={() => setSpins(false)}>
      <Turntable>
        <Float amplitude={0.3} speed={2}>
          <Icon3D color={color} path={path} isCCW={!invert} />
          {/* Transparent sphere to generate a shadow */}
          <Sphere args={[1, 4, 32]} scale-z={0.1}>
            <meshStandardMaterial opacity={0} transparent />
          </Sphere>
          {/* Bounding sphere for events */}
          <Sphere visible={false} />
        </Float>
      </Turntable>
    </a.group>
  )
}

function TechnologyIcons() {
  const idxMiddle = (icons.length - 1) / 2
  return (
    <div className="technologyIcons">
    <Canvas colorManagement={false} camera={{ position: [0, 1, 10] }} className="technologyIcons-canvas">
      <ambientLight intensity={[0.5]} />
      <pointLight position={[2, 2, 2]} />
      {/* <OrbitControls /> */}
      {icons.map((props, i) => (
        <AnimatedIcon className="technologyIcons-icon" key={i} {...props} position-x={(i - idxMiddle) * 2} />
      ))}
      <ContactShadows rotation-x={Math.PI / 2} position={[0, -1.5, 0]} opacity={0.8} width={10} height={10} blur={2} far={1.5} />
    </Canvas>
  </div>
  )
}

export default TechnologyIcons;
