import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Slide from 'react-reveal/Slide';

function Terms (){
  let
  tab1 = "Privacy Policy",
  tab2 = "Terms of Service",
  tab3 = "Cookie Policy";
  return(
    <React.Fragment>
      <div className="section fp-section fp-tabble">
        <div className="slider_content">
          <div className="container">
            <div className="row d-flex flex-wrap align-items-center justify-content-center">
              <div className="col-lg-5 col-sm-12 align-middle section-title order-2 order-lg-1">
                <h2 style={{color: '#ffffff'}} className="text-center text-lg-right text-md-right">Terms & Privacy</h2>
              </div>
              <div className="col-lg-6 col-sm-12 justify-content-center align-middle order-1 order-lg-2">
                <Slide right ssrFadeout>
                <img src="https://animusdevelopment.b-cdn.net/images/tech/technology.png" className="w-100" alt="Terms & Privacy" />
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Page Wrapper */}
      <div className="section fp-section fp-tabble">
        <div className="slider_content">
            <div className="container">

              <div className="row">
                <div className="col-lg-12">

                  <Tabs className="mb--50">
                    <TabList className="tab-style--2">
                      <Tab className="rn-button-style--2 d-flex"><span>{tab1}</span></Tab>
                      <Tab className="rn-button-style--2 d-flex"><span>{tab2}</span></Tab>
                      <Tab className="rn-button-style--2 d-flex"><span>{tab3}</span></Tab>
                    </TabList>

                    <TabPanel>
                      <div className="terms-tab-content">
                        <div className="mt--30">
                          <div className="section-title">
                          <h2 className="title">Privacy Policy</h2>
                        </div>
                          <p>Your privacy is important to us. It is Animus Bytes Ltd's policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://www.animusdevelopment.com">https://www.animusdevelopment.com</a>, and other sites we own and operate.</p>

                          <br />
                          <h3>Information we collect</h3>
                          <br />
                          <h4>Log data</h4>
                          <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. This data is considered “non-identifying information”, as it does not personally identify you on its own. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.</p>
                          <br />
                          <br />
                          <h4>Personal information</h4>
                          <p>We may ask for personal information, such as your name and email address. This data is considered “identifying information”, as it can personally identify you. We only request personal information relevant to providing you with a service, and only use it to help provide or improve this service.</p>
                          <br />
                          <br />
                          <h4>How we collect information</h4>
                          <p>We collect information by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used. You are free to refuse our request for this information, with the understanding that we may be unable to provide you with some of your desired services without it.</p>
                          <br />
                          <br />
                          <h3>Use of information</h3>
                          <p>We may use a combination of identifying and non-identifying information to understand who our visitors are, how they use our services, and how we may improve their experience of our website in future. We do not disclose the specifics of this information publicly, but may share aggregated and anonymised versions of this information, for example, in website and customer usage trend reports.</p>
                          <br />
                          <br />
                          <h3>Data processing and storage</h3>
                          <p>We only retain personal information for as long as necessary to provide a service, or to improve our services in future. While we retain this data, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure, and cannot guarantee absolute data security.</p>
                          <br />
                          <br />
                          <h3>Cookies</h3>
                          <p>We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit so we can understand how you use our site and serve you content based on preferences you have specified.</p>
                          <p>If you do not wish to accept cookies from us, you should instruct your browser to refuse cookies from our website, understanding that we may be unable to provide you with some of your desired services without them. This policy covers only the use of cookies between your computer and our website; it does not cover the use of cookies by any third-party services we use on our site.</p>
                          <br />
                          <br />
                          <h3>Third-party access to information</h3>
                          <p>We may use third-party services for our website and marketing activity. These services may access our data solely for the purpose of performing specific tasks on our behalf. We do not share any personally identifying information with these services without your explicit consent. We do not give these services permission to disclose or use any of our data for any other purpose.</p>
                          <p>We will refuse government and law enforcement requests for data if we believe a request is too broad or unrelated to its stated purpose. However, we may cooperate if we believe the requested information is necessary and appropriate to comply with legal process, to protect our own rights and property, to protect the safety of the public and any person, to prevent a crime, or to prevent what we reasonably believe to be illegal, legally actionable, or unethical activity.</p>
                          <p>We do not otherwise share or supply personal information to third parties. We do not sell or rent your personal information to marketers or third parties.</p>
                          <br />
                          <br />
                          <h3>Children’s Privacy</h3>
                          <p>This website does not knowingly target or collect personal information from children. As a parent/guardian, please contact us if you believe your child is participating in an activity involving personal information on our website, and you have no received a notification or request for consent. We do not use your supplied contact details for marketing or promotional purposes.</p>
                          <br />
                          <br />
                          <h3>Limits of our policy</h3>
                          <p>This privacy policy only covers Animus Bytes Ltd's own collecting and handling of data. We only work with partners, affiliates and third-party providers whose privacy policies align with ours, however we cannot accept responsibility or liability for their respective privacy practices.</p>
                          <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p>
                          <br />
                          <br />
                          <h3>Changes to this policy</h3>
                          <p>At our discretion, we may update this policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around data and personal information.</p>
                          <br />
                          <br />
                          <h3>Your rights and responsibilities</h3>
                          <p>As our user, you have the right to be informed about how your data is collected and used. You are entitled to know what data we collect about you, and how it is processed. You are entitled to correct and update any personal information about you, and to request this information be deleted.</p>
                          <p>You are entitled to restrict or object to our use of your data, while retaining the right to use your personal information for your own purposes. You have the right to opt out of data about you being used in decisions based solely on automated processing.</p>
                          <p>Feel free to contact us if you have any concerns or questions about how we handle your data and personal information.</p>
                          <br />
                          <br />
                          <p><strong>Animus Bytes Ltd Data Controller</strong><br />
                          info@animus.email</p>
                          <br />
                          <br />
                          <p>This policy is effective as of 1 September 2020.</p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="terms-tab-content">
                        <div className="mt--30">
                          <div className="section-title">
                          <h2 className="title">Animus Bytes Ltd Terms of Service</h2>
                        </div>
                          <h3>1. Terms</h3>
                          <p>By accessing the website at <a href="https://www.animusdevelopment.com">https://www.animusdevelopment.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
                          <br />
                          <br />
                          <h3>2. Use Licence</h3>
                          <ol type="a">
                            <li>Permission is granted to temporarily download one copy of the materials (information or software) on Animus Bytes Ltd's website for personal, non-commercial transitory viewing only. This is the grant of a licence, not a transfer of title, and under this licence you may not:
                              <ol type="i">
                                <li>modify or copy the materials;</li>
                                <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                <li>attempt to decompile or reverse engineer any software contained on Animus Bytes Ltd's website;</li>
                                <li>remove any copyright or other proprietary notations from the materials; or</li>
                                <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                              </ol>
                            </li>
                            <li>This licence shall automatically terminate if you violate any of these restrictions and may be terminated by Animus Bytes Ltd at any time. Upon terminating your viewing of these materials or upon the termination of this licence, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
                          </ol>
                          <br />
                          <br />
                          <h3>3. Disclaimer</h3>
                          <ol type="a">
                            <li>The materials on Animus Bytes Ltd's website are provided on an 'as is' basis. Animus Bytes Ltd makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                            <li>Further, Animus Bytes Ltd does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
                          </ol>
                          <br />
                          <br />
                          <h3>4. Limitations</h3>
                          <p>In no event shall Animus Bytes Ltd or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Animus Bytes Ltd's website, even if Animus Bytes Ltd or a Animus Bytes Ltd authorised representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                          <br />
                          <br />
                          <h3>5. Accuracy of materials</h3>
                          <p>The materials appearing on Animus Bytes Ltd's website could include technical, typographical, or photographic errors. Animus Bytes Ltd does not warrant that any of the materials on its website are accurate, complete or current. Animus Bytes Ltd may make changes to the materials contained on its website at any time without notice. However Animus Bytes Ltd does not make any commitment to update the materials.</p>
                          <br />
                          <br />
                          <h3>6. Links</h3>
                          <p>Animus Bytes Ltd has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Animus Bytes Ltd of the site. Use of any such linked website is at the user's own risk.</p>
                          <br />
                          <br />
                          <h3>7. Modifications</h3>
                          <p>Animus Bytes Ltd may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
                          <br />
                          <br />
                          <h3>8. Governing Law</h3>
                          <p>These terms and conditions are governed by and construed in accordance with the laws of UK and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="terms-tab-content">
                        <div className="mt--30">
                          <div className="section-title">
                          <h2 className="title">Cookie Policy</h2>
                        </div>
                          <p>We use cookies to help improve your experience of <a href="https://www.animusdevelopment.com">https://www.animusdevelopment.com</a>. This cookie policy is part of Animus Bytes Ltd's privacy policy, and covers the use of cookies between your device and our site.</p>
                          <p>If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from <a href="https://www.animusdevelopment.com">https://www.animusdevelopment.com</a>, with the understanding that we may be unable to provide you with some of your desired content and services.</p>
                          <br />
                          <br />
                          <h3>What is a cookie?</h3>
                          <p>A cookie is a small piece of data that a website stores on your device when you visit, typically containing information about the website itself, a unique identifier that allows the site to recognise your web browser when you return, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.</p>
                          <p>Cookies are used to enable certain features (eg. logging in), to track site usage (eg. analytics), to store your user settings (eg. timezone, notification preferences), and to personalise your content (eg. advertising, language).</p>
                          <p>Cookies set by the website you are visiting are normally referred to as “first-party cookies”, and typically only track your activity on that particular site. Cookies set by other sites and companies (ie. third parties) are called “third-party cookies”, and can be used to track you on other websites that use the same third-party service.</p>
                          <br />
                          <br />
                          <h3>How you can control or opt out of cookies</h3>
                          <p>If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our website. Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie.</p>
                          <p>If you browse websites from multiple devices, you may need to update your settings on each individual device.</p>
                          <p>Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean you are unable to access certain features and content across the sites you visit.</p>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  )
}



export default Terms;
