import React, { Component } from "react";
import Deck from "./deck/Deck"
import { GiClick } from "react-icons/gi";
import Slide from 'react-reveal/Slide';

class Process extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="section fp-section fp-tabble slider_container pb_sm--200">
                      <div className="slider_content container">
                                    <div className="row">
                                        <div className="col-lg-7 col-md-6 col-sm-12 order-1 position-relative">
                                                <div className="section-title pr--10">
                                                    <h2 style={{color: '#ffffff'}} className="title text-left">Process</h2>
                                                    <p style={{color: '#d7d7d7', fontSize: '1.2em'}} className="description">Many of our clients come to us unsure about how to build software, but many are also unsure about their product itself. We act as our clients’ own product development team, collaborating closely to help refine ideas and build the necessary software to get products to launch.</p>
                                                    <p style={{color: '#d7d7d7', fontSize: '1.2em', fontWeight: '800'}} className="description">Deal the cards to find out more.<span><GiClick className="card-arrow" /></span></p>
                                                </div>
                                        </div>
                                        <div className="col-lg-5 col-md-6 col-sm-12 order-2 card_wrapper mt--50">
                                        <Deck />
                                        </div>
                                    </div>
                                </div>
                            </div>
            </React.Fragment>
        )
    }
}
export default Process;
