import React, { Component } from "react";
import Slide from 'react-reveal/Slide';

class About extends Component{
  render(){
      let
      description = 'For some years our Teams have been on the receiving end of product, training and consultancy that is the result of financially incentivised deals, nepotism within authority figures in our society and a wider lack of risk appetite for just doing what is right for everyone.',
      description2 = 'A convenient measure of success in our culture is profit and loss. Lip service is paid to people\'s welfare and kindness, to ourselves and to others. We hear so called business leaders stand up and talk about the benefits of working for them - occasionally \'with them\' in a traditionally toe-curlingly distant way - and all they are really talking about is giving more carrot than stick.',
      description3 = 'There is nothing missing in the signs and signals that shouldn\'t make us uncomfortable in what we see, and yet we continue to work for and focus on profit and loss to the point that we measure our lives and our own value by those things. We are evermore distanced from what it is that matters, no one gets to the very end wishing they had more cash in the bank.',
      description4 = '𝝠nimus is the antidote in a small way. Through what we make, how we speak and how we help others feel. We polarise ourselves from the status quo. It is necessary that we solve our customer\'s problems understanding that sometimes we will do that in a way that is unsuccessful by traditional measures of success but in doing so we set the conditions for ongoing relationships built on trust, humility and understanding - that despite the initial cynicism, our customers and everyone around us becomes comfortable with the fact that we are doing this for a reason greater than making a quick buck.',
      description5 = '𝝠nimus create products. But really we unapologetically express our belief in everything we do. Our product just happens to be one vehicle of expression as our art, our conversation and our actions are other expressions.',
      description6 = 'We\'re not setting out to boil the ocean. In fact the opposite is true. We\'re setting out to remain as small as possible - but small in number doesn\'t mean small in voice or impact.';
      return(
          <React.Fragment>
            <div className="section fp-section fp-tabble section_one">
              <div className="slider_content">
                  <div className="container">
                    <div className="row d-flex flex-wrap align-items-center justify-content-center">
                        <div className="col-lg-6 col-sm-12 align-middle">
                            <img src="https://animusdevelopment.b-cdn.net/images/about/Animus-space.png" className="w-100" alt="About Us" />
                        </div>
                        <div className="col-lg-6 col-sm-12 align-middle section-title">
                          <h2 style={{color: '#ffffff'}} className="text-center text-lg-left text-md-left">About Us</h2>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section fp-section fp-tabble section_two">
                  <div className="rn-about-wrapper">
                      <div className="container">
                          <div className="row row--35 align-items-center">
                              <div className="col-lg-12">
                                      <div className="section-title">
                                          <h1 className="title">Vision</h1>
                                          <p className="description" style={{fontSize: '3em', lineHeight: '2em'}}>Surpass the Military-Industrial complex through our value, creativity and spirit.</p>
                                      </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="section fp-section fp-tabble section_two">
                  <div className="rn-about-wrapper">
                      <div className="container">
                          <div className="row row--35 align-items-center">
                              <div className="col-lg-6 order-1 order-lg-2">
                                  <div className="thumbnail">
                                      <img className="w-100 pt_sm--50 pb_sm--50" src="https://animusdevelopment.b-cdn.net/images/about/about-4.png" alt="About Images"/>
                                  </div>
                              </div>
                              <div className="col-lg-6 order-2 order-lg-1">
                                      <div className="section-title">
                                          <h2 className="title">Who?</h2>
                                          <p className="description">{description5}</p>
                                          <p className="description">{description6}</p>
                                      </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="section fp-section fp-tabble section_two">
                  <div className="rn-about-wrapper">
                      <div className="container">
                          <div className="row row--35 align-items-center">
                              <div className="col-lg-6 order-1 order-lg-1">
                                <div className="thumbnail">
                                  <Slide left ssrFadeout>
                                    <img className="w-100 pt_sm--50 pb_sm--50" src="https://animusdevelopment.b-cdn.net/images/about/landrover.png" alt="Animus Defender"/>
                                    </Slide>
                                </div>
                              </div>
                              <div className="col-lg-6 order-2 order-lg-2">
                                      <div className="section-title">
                                          <h2 className="title">Why?</h2>
                                          <p className="description">{description}</p>
                                          <p className="description">{description2}</p>
                                      </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="section fp-section fp-tabble section_two">
                  <div className="rn-about-wrapper">
                      <div className="container">
                          <div className="row row--35 align-items-center">
                              <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                  <Slide right ssrFadeout>
                                    <img className="w-100 pt_sm--50 pb_sm--50" src="https://animusdevelopment.b-cdn.net/images/about/revoltez-vous.png" alt="Revoltez Vous"/>
                                  </Slide>
                                </div>
                              </div>
                              <div className="col-lg-6 order-2 order-lg-1">
                                      <div className="section-title">
                                          <h2 className="title">How?</h2>
                                          <p className="description">{description3}</p>
                                          <p className="description">{description4}</p>
                                      </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </React.Fragment>
        )
      }
    }
    export default About;
