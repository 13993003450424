// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Home layout
import HomePage from './elements/Home';
import CustomSoftwarePage from './elements/CustomSoftware';
import SoftwareProductPage from './elements/SoftwareProduct';
import PrototypingPage from './elements/Prototyping';
import DataCollectionPage from './elements/DataCollection';
import TechnologyPage from "./elements/Technology";
import AboutPage from "./elements/About";
import ContactPage from "./elements/ContactSection";
// import NewsPage from "./elements/News";
// import NewsDetails from "./elements/News/NewsDetails";
import TermsPage from "./elements/Terms";
import VisualEffects from './elements/VisualEffects';
import error404 from "./elements/error404";
import * as serviceWorker from './serviceWorker';
import Loading from './elements/Loader/loading';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

class Root extends Component {
  constructor(props) {
    super(props);
      this.state = { isLoading: true }
  }

  componentDidMount() {
      this.setState({isLoading: false})
  }

  render() {
      return(
        <>
        {this.state.isLoading ? (
          <Loading />
        ) : (
            <Router>
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route exact path="/technology" component={TechnologyPage}/>
                    <Route exact path="/custom-software" component={CustomSoftwarePage}/>
                    <Route exact path="/software-product" component={SoftwareProductPage}/>
                    <Route exact path="/prototyping" component={PrototypingPage}/>
                    <Route exact path="/data-collection" component={DataCollectionPage}/>
                    <Route exact path="/visual-effects" component={VisualEffects}/>
                    <Route exact path="/contact" component={ContactPage}/>
                    <Route exact path="/about" component={AboutPage}/>
                    {/* <Route exact path="/news" component={NewsPage}/>
                    <Route path="/news/:slug" component={NewsDetails}/> */}
                    <Route exact path="/terms" component={TermsPage}/>
                    <Route path="/404" component={error404}/>
                    <Route component={error404} />
                    <Redirect to="/404" />
                    <Route path="*" component={error404} />

                </Switch>
            </Router>
          )}
        </>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          if (
            window.confirm(
              "There is a new version of the app ready. Please reload to update."
            )
          ) {
            window.location.reload()
          }
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  },
})
