export default {
  callbacks: ["onLeave"],
  navigation: false,
  scrollOverflow: false,
  arrowNavigation: false,
  autoScrolling: true,
  fitToSection: true,
  responsiveSlides: true,
  responsiveWidth: 6000,
  verticalCentered: true,
  touchSensitivity: 8,
  paddingTop: '2em',
  dragAndMove: "fingersonly",
  licenseKey: '19752CE7-7B55496E-9C34963A-39D33105',
};
