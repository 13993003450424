import React, { Component } from "react";
import Slide from 'react-reveal/Slide';
import ServiceList from './ServiceList';
import Magic from "./Magic";

class CustomSoftware extends Component{
  render(){
    return(
      <React.Fragment>
        {/* <div className="section fp-section fp-tabble">
          <div className="slider_content">
              <div className="container">
                <div className="row d-flex flex-wrap align-items-center justify-content-center">
                  <div className="col-lg-6 col-sm-12 align-middle section-title order-2 order-lg-1">
                    <h2 style={{color: '#ffffff'}} className="text-center text-lg-right text-md-right">Custom Software Development</h2>
                  </div>
                    <div className="col-lg-6 col-sm-12 align-middle order-1 order-lg-2">
                        <img className="w-100" src="https://animusdevelopment.b-cdn.net/images/customsoftware/head-image-2.png" alt="Custom Web App" />
                    </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="section slider_container fp-section fp-tabble" data-black-overlay="7">
            <div className="slider_content">
              <div className="container">
                <div className="row d-flex flex-wrap align-items-center">
                    <div className="col-lg-12 col-sm-12">
                      <div className="mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                        <div className="row d-flex flex-wrap align-items-center">
                        <div className="col-lg-6 col-sm-12 order-1 order-lg-2">
                        <div className="section-title">
                          <h2 style={{color: '#ffffff'}} className="title">Custom Software</h2>
                        </div>
                        <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>Companies looking for software to solve problems and transform data face a challenging dilemma, do they invest in a fully custom software solution or go with an off-the-shelf product? 𝝠nimus specialise in helping our clients achieve their goals through custom software solutions based on a deep understanding of our clients intent, challenges and needs.</p>
                        <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>In recent years, custom micro-services based software solutions have deservedly increased their market share because of the incredible value they bring to users. Read on to learn why a custom solution may be a good fit for your brand!</p>
                        <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>Read on to learn why a custom solution may be a good fit for your brand!</p>
                      </div>
                      <div className="col-lg-6 col-sm-12 order-2 order-lg-1 align-items-center align-middle mt_md--100 mt_sm--70 mb_sm--70 pt_lg--50">
                        <img src="https://animusdevelopment.b-cdn.net/images/customsoftware/custom-software-intro.png" alt="Custom Software intro" />
                      </div>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section slider_container fp-section fp-tabble service-area" data-black-overlay="7">
             <div className="container">
                 <div className="row mb_sm--100">
                     <div className="col-lg-6 col-sm-12 float-left order-1 order-lg-1 mb_sm--150">
                          <div className="section-title text-left service-style--3 mb--30">
                              <h2 className="title">Custom Software Services</h2>
                              <p>𝝠nimus presents a unique service package to suit your needs. We've planned, built and implemented custom software solutions for over dozens of corporate clients, from oil and gas to healthcare and entertainment.</p>
                          </div>
                     </div>
                     <div className="col-lg-6 col-sm-12 float-right order-2 order-lg-2">
                      <ServiceList />
                    </div>
                </div>
             </div>
          </div>
          <div className="section slider_container fp-section fp-tabble" data-black-overlay="7">
            <div className="slider_content">
              <div className="container">
                <div className="row">
                  <div className="d-flex col-lg-6 mt_md--50 mt_lg--50 mt--50 order-2 order-lg-1">
                      <Magic />
                  </div>
                    <div className="col-lg-6 col-sm-12 order-1 order-lg-2">
                      <div className="mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                        <div className="section-title">
                          <h2 style={{color: '#ffffff'}} className="title">Benefits of Custom Software</h2>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>There are clearly benefits both for custom software and off-the-shelf solutions. By choosing custom software development from us, you'll enjoy numerous advantages of a custom system, tailored for your business. If you want to know more on the advantages of a custom software solution versus an off-the-shelf, click one of the tiles below.</p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section fp-section fp-tabble slider_container pb_mobile--100" data-black-overlay="7">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service-details-inner">
                    <div className="inner">
                      <div className="row sercice-details-content align-items-center">
                        <div className="col-lg-6 col-sm-12">
                          <div className="details mt_md--30 mt_sm--30" style={{borderRadius: '10px'}}>
                            <div className="section-title">
                              <h2 className="title" style={{color: '#ffffff'}}>Specialist Applications</h2>
                            </div>
                            <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>Our team of software engineers creates dynamic, innovative applications that are both efficient in performance and are easy to use. From ERP systems, documentation applications, Client Management systems, Vulnerability Assessment tools and Open Source Intelligence platforms, we build solutions that optimise critical business processes and functions.</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 pb--50">
                          <div className="thumb">
                            <img className="w-100" src="https://animusdevelopment.b-cdn.net/images/customsoftware/Animus_app.png" alt="Animus App"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </React.Fragment>
        )
      }
    }
    export default CustomSoftware;
