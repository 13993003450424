import React, { Component } from "react";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import CustomSoftware from "./CustomSoftware";

class CustomSoftwarePage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/custom-software.webp'} helmetTitle={'Custom Software'}>
      <CustomSoftware />
      </AnimusWrapper>
    );
  }
}

export default CustomSoftwarePage;
