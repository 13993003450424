import React, { Component } from "react";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import DataCollection from "./DataCollection";

class DataCollectionPage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/data-collection.webp'} helmetTitle={'Data Collection'}>
      <DataCollection />
      </AnimusWrapper>
    );
  }
}

export default DataCollectionPage;
