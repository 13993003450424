import React, { Component } from "react";
import { Link } from "react-router-dom";

class CallToAction extends Component{
  render(){
      return(
          <React.Fragment>
            <div className="section fp-section fp-tabble call-to-action">
              <div className="slider_content">
                  <div className="call-to-action-image"></div>
                  <div className="container">
                    <div className="row d-flex justify-content-center text-center">
                        <div className="col-lg-6 col-sm-12 section-title pt_sm--70 justify-content-center">
                          <h2 style={{
                            color: '#ffffff',
                            textShadow: '6px 6px rgba(0,0,0,0.1)',
                            fontFamily: 'adobe-garamond-pro,"Helvetica Neue",Helvetica,Arial,sans-serif',
                            fontSize: '4em',
                            fontWeight: '900',
                            fontStyle: 'italic'
                          }}
                          className="title">What's it worth to you? We can help
                        </h2>
                        </div>
                          <div className="main-buttons col-lg-12">
                            <div className="text-center">
                            <Link className="btn-view" to="/contact">Let's talk</Link>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
          </React.Fragment>
        )
      }
    }
    export default CallToAction;
