import React, { Component } from "react";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import Technology from "./Technology";
import BackEnd from "./BackEnd";
import FrontEnd from "./FrontEnd";
import BigData from "./BigData";
import Deployment from "./Deployment";

class TechnologyPage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/technology.webp'} helmetTitle={'Technology'}>
      {/* <Technology /> */}
      <BackEnd />
      <FrontEnd />
      <BigData />
      <Deployment />
      </AnimusWrapper>
    );
  }
}

export default TechnologyPage;
