import React from 'react';


export default class Loading extends React.Component {
  render () {
    return(
      <div className="loading">
        {/* <MatrixCard
          styleOverrideForContainerDiv={{backgroundColor: 'rgba(42, 40, 45, 0)'}}
          styleOverrideForChildrenDiv={{backgroundColor: 'rgba(42, 40, 45, 0)'}}
          matrixText={"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}"}
          delay={50}
          backgroundColor={"rgba(0, 0, 0, 0.2)"}
          textFontSize={"16"}
          textMainColor={"#00FF41"}
          textAlternateColorRatio={0.1}
          textAlternateColorList={["#008F11", "#003B00", "#00FF41", "#C0C0C0"]}

          > */}
            <div className="container">
              <div className="row d-flex flex-wrap align-items-center justify-content-center">
                <div className="text-center col-lg-12 align-middle">
                  <table style={{height: '100vh', borderCollapse: 'collapse', border: 'none'}} className="table-borderless">
                    <tbody>
                      <tr>
                        <td style={{textAlign: 'center', borderStyle: '0'}}><h1 style={{fontFamily: 'Beckman'}} className="text-flicker-in-glow">𝝠nimus</h1></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          {/* </MatrixCard> */}
        </div>
      );
    }
  }
