import React, { Component } from "react";

class DataCollection extends Component{
  render(){
    return(
      <React.Fragment>
        {/* <div className="section fp-section fp-tabble" data-black-overlay="7">
          <div className="slider_content">
              <div className="container">
                <div className="row d-flex flex-wrap align-items-center justify-content-center">
                  <div className="col-lg-6 col-sm-12 align-middle section-title order-2 order-lg-2">
                    <h2 style={{color: '#ffffff'}} className="text-center text-lg-left text-md-left">Data Collection & Analysis</h2>
                  </div>
                    <div className="col-lg-6 col-sm-12 align-middle order-1 order-lg-1">
                        <img src="https://animusdevelopment.b-cdn.net/images/datacollection/data-head-image.png" className="w-100" alt="Custom Web App"/>
                    </div>
                </div>
              </div>
            </div>
          </div> */}
            {/* End Breadcrump Area */}
            <div className="section slider_container fp-section fp-tabble" data-black-overlay="7">
              <div className="slider_content">
                <div className="container">
                  <div className="row d-flex flex-wrap align-items-center">
                      <div className="col-lg-12 col-sm-12 order-1 order-lg-1">
                        <div className="mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                          <div className="row d-flex flex-wrap align-items-center">
                          <div className="col-lg-6 col-sm-12 order-1 order-lg-1">
                          <div className="section-title">
                            <h2 style={{color: '#ffffff'}} className="title">Data Collection</h2>
                          </div>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>𝝠nimus can integrate data of any type, size, source, or format while preserving the integrity and classification of source systems. Granular access controls govern how users can interact with data according to role, classification, or purpose. 𝝠nimus can also integrate streaming data, such as video feeds, in real time, and federate searches to external sources.</p>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>Once integrated, 𝝠nimus provides tools to refine high-noise, high-volume datasets, and infrastructure to deploy, train, evaluate, and improve machine learning. By augmenting human judgment and expertise with technology that makes massive-scale data manageable and useful, 𝝠nimus lets analysts spend less time manipulating data and more time understanding and engaging with it.</p>
                        </div>

                        <div className="col-lg-6 col-sm-12 order-2 order-lg-2 align-items-center mt_md--100 mt_sm--70 mb_sm--70 pt_lg--50">
                          <img className="w-100" src="https://animusdevelopment.b-cdn.net/images/datacollection/data-collection.png" style={{display: 'block'}} alt="Data Collection" />
                        </div>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section slider_container fp-section fp-tabble" data-black-overlay="7">
              <div className="slider_content">
                <div className="container">
                  <div className="row d-flex flex-wrap align-items-center">
                      <div className="col-lg-12 col-sm-12 order-1 order-lg-1">
                        <div className="mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                          <div className="row d-flex flex-wrap align-items-center">
                          <div className="col-lg-6 col-sm-12 order-1 order-lg-2">
                          <div className="section-title">
                            <h2 style={{color: '#ffffff'}} className="title">Data Analysis</h2>
                          </div>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>Our software allows our clients to use an integrated method for collaborative data analysis, empowering operators with the data they need when they need it. 𝝠nimus gives everyone from analysts to executives the tools they need to make decisions based on a compounding knowledge asset. Nontechnical users don’t need to learn specialised query languages to discover and analyse data, and data science toolkits let technical users make full use of their own data foundation.</p>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>As proven commercial software already in use, 𝝠nimus is ready to deploy off the shelf. Clients can apply cutting-edge technology to their most urgent problems without delay.</p>
                        </div>

                        <div className="col-lg-6 col-sm-12 order-2 order-lg-1 align-items-center mt_md--100 mt_sm--70 mb_sm--70 pt_lg--50">
                          <img className="w-100" src="https://animusdevelopment.b-cdn.net/images/datacollection/data-analysis.png" style={{display: 'block'}} alt="Data Analysis" />
                        </div>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    }
    export default DataCollection;
