import React from "react";
import { input } from 'formik';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {

    const { status } = this.state;
    return (
      <React.Fragment>

        <form onSubmit={this.submitForm} action="https://formspree.io/f/xvowjboj" method="POST">
        <div className="contact-form--1">
          <div className="row align-items-start">
            <div className="col-lg-12 order-1 order-lg-1">
              <div className="section-title text-left mb--50 pt_sm--100">
                <h2 className="">Talk with us</h2>
              </div>

              <div className="col-lg-6 float-left">
                <div className="row">


                  <div className="col-lg-6 float-left">
                    <label>First Name</label>
                    <input

                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      required
                    />

                  </div>


                  <div className="col-lg-6 float-right">
                    <label>Last Name</label>
                    <input

                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      required
                    />




                  </div>
                </div>
                <div className="row">


                  <div className="col-lg-6 float-left">
                    <label>Business Email</label>
                    <input

                      type="text"
                      name="email"
                      placeholder="Business Email"
                      required
                    />


                  </div>


                  <div className="col-lg-6 float-right">
                    <label>Phone #</label>
                    <input

                      type="tel"
                      name="phone"
                      placeholder="Phone Number"
                      required
                    />

                  </div>
                </div>


                <label>Company Name</label>
                <input

                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                />

              </div>


              <div className="col-lg-6 float-right">
                <label>How can we help?</label>
                <textarea
                  type="text"
                  name="message"
                  style={{height: '255px'}}
                  placeholder="How can we help?"
                />

              </div>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-lg-12 contact-button">
              {status === "SUCCESS" ? <div className="row justify-content-center"><div className="col-lg-6 alert alert-success">Success! Thank you - we'll be in touch very soon!</div></div> : <button type="submit" className="rn-button-style--2 btn-solid" style={{marginTop: '20px', marginBottom: '20px'}}>Submit</button>}
              {status === "ERROR" && <div className="row justify-content-center"><div className="col-lg-6 alert alert-danger">There was an error. Please try again or call us on +44 33 3335 5671</div></div>}
            {/* <span className="spinner-border spinner-border-sm mr-1"></span> : <button type="submit" className="rn-button-style--2 btn-solid" style={{marginTop: '20px', marginBottom: '20px'}}>Submit</button>} */}
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center text-center">
            <h3>Or call anytime <a href="tel:+44 33 3335 5671" style={{textDecoration: 'none'}}>+44 33 3335 5671</a></h3>
          </div>
        </div>
  </form>
</React.Fragment>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
