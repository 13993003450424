import React, { Component } from "react";
import AnimusWrapper from "./AnimusWrapper";
import Terms from "./Terms";

class TermsPage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/terms.webp'} helmetTitle={'Terms & Privacy'}>
      <Terms />
      </AnimusWrapper>
    );
  }
}

export default TermsPage;
