import React ,{ Component }from "react";
import ReactCardCarousel from "react-card-carousel";



class Lifecycle extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                  <div className="software-product-lifecycle">
                      <ReactCardCarousel spread={"wide"} autoplay={true} autoplay_speed={2000} alignment={"horizontal"} disable_box_shadow disable_keydown={true} ref={ this.Carousel }>
                        <div>
                          <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/lifecycle/product-development-1.png" alt="Product Lifecycle" />
                        </div>
                        <div>
                          <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/lifecycle/product-development-2.png" alt="Product Lifecycle" />
                        </div>
                        <div>
                          <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/lifecycle/product-development-3.png" alt="Product Lifecycle" />
                        </div>
                        <div>
                          <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/lifecycle/product-development-4.png" alt="Product Lifecycle" />
                        </div>
                        <div>
                          <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/lifecycle/product-development-5.png" alt="Product Lifecycle" />
                        </div>
                        <div>
                          <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/lifecycle/product-development-6.png" alt="Product Lifecycle" />
                        </div>
                        <div>
                          <img src="https://animusdevelopment.b-cdn.net/images/softwareproduct/lifecycle/product-development-7.png" alt="Product Lifecycle" />
                        </div>
                    </ReactCardCarousel>
                  </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Lifecycle;
