import * as THREE from 'three'
import React, { Suspense, useEffect, useState } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { Reflector, Text, useTexture, useGLTF } from '@react-three/drei'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useTransition, a } from 'react-spring'
import { FaVolumeUp } from "react-icons/fa";

function Loading() {
  const [finished, set] = useState(false)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    THREE.DefaultLoadingManager.onLoad = () => set(true)
    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) =>
      setWidth((itemsLoaded / itemsTotal) * 200)
  }, [])

  const props = useTransition(finished, null, {
    from: { opacity: 1, width: 0 },
    leave: { opacity: 0 },
    update: { width },
  })

  return props.map(
    ({ item: finished, key, props: { opacity, width } }) =>
      !finished && (
        <>
        <a.div className="loading" key={key} style={{ opacity }}>
          <div className="loading-bar-container">
            <a.div className="loading-bar" style={{ width }} />
          </div>
        </a.div>
        </>
      ),
  )
}

 function Overlay({ ready, clicked, setClicked }) {
  return (
    <>
      <div style={{ backgroundColor: '#171717'}} className={`loading-wrapper fullscreen bg ${ready ? 'ready' : 'notready'} ${clicked && 'clicked'}`}>
        <div onClick={() => ready && setClicked(true)}>{!ready ?
          <><p className="loading-title">Loading</p><Loading /></> :
          <>
          <div className="loading-text-button"></div>
        <div className="align-items-center text-center loading-finished">
          <div className="loading-finished-text">
            <p className="finished-text-title">Click to begin</p>
            <p style={{fontSize: '2rem'}}><FaVolumeUp /></p>
            <p>We use music and sounds in this section.<br />Please turn up your volume.</p>
          </div>
        </div>
      </>
      }</div>
      </div>
    </>
  )
}

function Audi(props) {
    const { scene } = useGLTF('/Animus_Website_Car.glb')
  return <primitive object={scene} {...props} />
}

function VideoText({ clicked, ...props }) {
  const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/Dance_montage.mp4', crossOrigin: 'Anonymous', loop: true }))
  useEffect(() => void (clicked && video.play()), [video, clicked])
  return (
    <Text font="/assets/fonts/Beckman-Black.otf" fontSize={5} letterSpacing={-0.06} {...props}>
      VFX
      <meshBasicMaterial toneMapped={false}>
        <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
      </meshBasicMaterial>
    </Text>
  )
}

function Ground() {
  // const [floor, normal] = useTexture(['/SurfaceImperfections003_1K_var1.jpg', '/SurfaceImperfections003_1K_Normal.jpg'])
  // const [floor, normal] = useTexture(['/concrete.jpg', '/concrete_Normal.jpg'])
  const [colorMap, displacementMap, normalMap, roughnessMap, aoMap] = useLoader(TextureLoader, [
  '/concrete/ulymaigo_2K_Albedo.jpg',
  '/concrete/ulymaigo_2K_Displacement.jpg',
  '/concrete/ulymaigo_2K_Normal.jpg',
  '/SurfaceImperfections003_1K_var1.jpg',
  '/concrete/ulymaigo_2K_AO.jpg',
])
  return (
    <Reflector resolution={512} args={[30, 30]} mirror={0.4} mixBlur={8} mixStrength={1} rotation={[-Math.PI / 2, 0, Math.PI / 2]} blur={[400, 100]}>
      {(Material, props) =>
        <Material
        // color="#a0a0a0"
        metalness={0.4}
        // roughnessMap={floor}
        // normalMap={normal}
        normalScale={[1, 1]}
        displacementScale={0.2}
        map={colorMap}
        displacementMap={displacementMap}
        normalMap={normalMap}
        roughnessMap={roughnessMap}
        aoMap={aoMap}
        {...props}
      />}
    </Reflector>
  )
}

function Intro({ start, set }) {
  const [vec] = useState(() => new THREE.Vector3())
  useEffect(() => setTimeout(() => set(true), 500), [])
  return useFrame((state) => {
    if (start) {
      state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 2, 14), 0.05)
      state.camera.lookAt(0, 0, 0)
    }
  })
}

export default function GroundReflection() {
  const [clicked, setClicked] = useState(false)
  const [ready, setReady] = useState(false)
  const store = { clicked, setClicked, ready, setReady }
  return (
    <>
      <Canvas concurrent gl={{ alpha: false }} pixelRatio={[1, 1.5]} camera={{ position: [0, 3, 100], fov: 15 }} style={{height: '100vh', width: '100vw', zIndex: '1'}}>
        <color attach="background" args={['#171717']} />
        <fog attach="fog" args={['#171717', 15, 20]} />
        <Suspense fallback={null}>
          <group position={[0, -1, 0]}>
            <Audi rotation={[0, Math.PI - 4, 0]} position={[0, 0, 0]} scale={[1, 1, 1]} />
            <VideoText {...store} position={[0, 1.3, -2]} />
            <Ground />
          </group>
          <ambientLight intensity={0.2} />
          <spotLight position={[0, 10, 0]} intensity={0.4} />
          <directionalLight position={[-20, 0, -10]} intensity={0.2} />
          <Intro start={ready && clicked} set={setReady} />
        </Suspense>
      </Canvas>
      <Overlay {...store} />
    </>
  )
}
