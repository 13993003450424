export const items = [
  {
    css:
      "url(https://videodelivery.net/c5c67d97ad7fbc5ee85910c50ac6df79/thumbnails/thumbnail.jpg?time=3s)",
    height: 150
  },
  {
    css:
      "url(https://videodelivery.net/3ef3be6ad06d3ad5b576d4afbba561c1/thumbnails/thumbnail.jpg?time=1s)",
    height: 300
  },
  {
    css:
      "url(https://videodelivery.net/0994941923440398110812e66d38d9cc/thumbnails/thumbnail.jpg?time=1s)",
    height: 300
  },
  {
    css:
      "url(https://videodelivery.net/3427bce5348b1a81941a130cd322da09/thumbnails/thumbnail.jpg?time=4s)",
    height: 300
  },
  {
    css:
      "url(https://videodelivery.net/97e507c65a61e9aa3629a895b6d743f5/thumbnails/thumbnail.jpg?time=3s)",
    height: 300
  }
];
