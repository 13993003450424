import React ,{ Component }from "react";
import { FaMobileAlt, FaChevronCircleDown, FaChevronCircleUp, FaGlobe, FaGreaterThanEqual, FaClinicMedical, FaCreditCard, FaGopuram, FaWrench, FaGem  } from "react-icons/fa";
import ReactCardCarousel from "react-card-carousel";

const ServiceList = [
{
  icon: <FaGlobe />,
  title: 'Enterprise Applications',
  description: 'Our elite team of software engineers creates dynamic, innovative ERP applications. We build solutions that optimize critical business processes and functions including inventory and construction management, accounting, HR, and more.'
},
{
  icon: <FaGreaterThanEqual />,
  title: 'SaaS Applications',
  description: '𝝠nimus has extensive experience building a wide scope of commercial SaaS-based applications. We understand the specific challenges and opportunities of this process, and will leverage our knowledge to build your SaaS solution on-time and on-budget.'
},
{
  icon: <FaClinicMedical />,
  title: 'HIPAA compliant applications',
  description: '𝝠nimus specializes in creating advanced HIPAA-compliant applications with the most innovative and secure technologies. Our experts handle the software application from the architecture to delivery, ensuring it meets the strict government guidelines to protect personal data.'
},
{
  icon: <FaCreditCard />,
  title: 'Ecommerce Applications',
  description: 'If your business sells products or services online, a custom ecommerce application is an essential competitive advantage. 𝝠nimus builds applications that streamline payment, inventory, reporting, and security to keep your business thriving.'
},
{
  icon: <FaMobileAlt />,
  title: 'Mobile Applications',
  description: '𝝠nimus\'s expert engineers build iOS, Android, and Hybrid mobile applications that can stand alone or be integrated with web applications. We ensure your application passes rigorous acceptance standards with flying colors and gets published quickly.'
},
{
  icon: <FaGopuram />,
  title: 'Legacy Systems',
  description: 'Businesses must innovate to stay relevant, and 𝝠nimus can help take your older software to the next level. We rebuild legacy systems to be more dynamic, user-friendly, and efficient, based on cutting-edge technologies.'
},
{
  icon: <FaGem />,
  title: 'Third-Party Applications',
  description: 'In addition to building software from scratch, 𝝠nimus can support or take over solutions from third-party vendors. We start by benchmarking the application\'s quality so you have a clear picture of what it needs and how to get there.'
},
{
  icon: <FaWrench />,
  title: 'Product Development',
  description: 'From idea to prototype to release, 𝝠nimus can help your company through the entire life cycle of new product development. We\'ve built hundreds of products at unbeatable values, and our expertise for building innovative solutions is unparalleled.'
}
]


class ServiceThree extends Component{
  constructor(props) {
    super(props);
    this.Carousel = React.createRef();
    this.handleClickDown = this.handleClickDown.bind(this);
    this.handleClickUp = this.handleClickUp.bind(this);
  }

  handleClickDown() {
    this.Carousel.current.prev();
  }

  handleClickUp() {
    this.Carousel.current.next();
  }

    render(){
        const {column} = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row">
                  <div className="custom-software-services">
                      <ReactCardCarousel spread={"narrow"} autoplay={true} autoplay_speed={5000} alignment={"vertical"} disable_box_shadow disable_keydown={true} ref={ this.Carousel }>
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                                <div className="service service__style--2 custom-software-services-card">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                        </div>
                        ))}
                    </ReactCardCarousel>
                  </div>
                  <div className="custom-software-services-arrow">
                    <p className="text-center"><FaChevronCircleUp className="solution-click" style={{fontSize: '3em', cursor: 'pointer'}} onClick={this.handleClickUp} /></p>
                    <br />
                    <p className="text-center"><FaChevronCircleDown className="solution-click" style={{fontSize: '3em', cursor: 'pointer'}} onClick={this.handleClickDown} /></p>
                  </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
