import React, { Component } from "react";
import { FaMeteor, FaRegSun, FaHammer, FaTerminal, FaGamepad } from "react-icons/fa";
import Typist from 'react-text-typist';
import styled from 'styled-components';

const StyledTypist = styled(Typist)`
  white-space: pre;
`;

const ServiceList = [
  {
    icon: <FaMeteor />,
    title: 'Custom Software Development',
    description: 'We listen to your ideas and create custom software solutions from the ground up, designed especially for your business. 𝝠nimus customers agree that our team are some of the best in the world and that we\'re all doing it for the right reason.'
  },
  {
    icon: <FaRegSun />,
    title: 'Software Product Development',
    description: 'We create, engineer, and deliver software solutions for specialist requirements. 𝝠nimus customers get the best results and value, as well as our passion, expertise, and experience.'
  },
  {
    icon: <FaHammer />,
    title: 'Prototyping, Research & Development',
    description: 'Explore mission-boosting concepts before taking them into the field – with robust testing, project predictions and expert roadmapping 𝝠nimus use their combined background of experience to bring you force multiplying effects.'
  },
  {
    icon: <FaTerminal />,
    title: 'Data Collection & Analysis',
    description: 'Whether for an 𝝠nimus application or for your existing intelligence platform, we can collect multiple data types from open and closed sources at scale to feed your data store.'
  },
  {
    icon: <FaGamepad />,
    title: 'Animation, Modelling & VFX',
    description: 'Whether for an 𝝠nimus application or for your existing intelligence platform, we can collect multiple data types from open and closed sources at scale to feed your data store.'
  }
]

class Home extends Component {
  render() {
    return (
      <div className="section fp-section fp-tabble slider_container">
        <div className="container slider_content">
          <div className="row pt--100" id="home">
            <div className="col-lg-12 align-items-center section-title">
              <h1 className="title" style={{fontFamily: 'NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif'}}>
                <StyledTypist sentences={['Data.']} writeSpeed={3} showCursor={false} loop={false}/>
                <br />
                <StyledTypist sentences={['Developed.']} startDelay={1000} writeSpeed={3} showCursor={false} loop={false}/>
              </h1>
            </div>
          </div>
          <div
            className="img-main"
            data-aos="fade-down"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            {/* <p class="extra-padding Hero__Text-okfuhs-5 eMpOcq">Right this way<span class="Arrow__ArrowContainer-sc-1r0p4za-0 dDHSJq"><svg class="Hero__Arrow-okfuhs-4 hsRDwd Arrow__ArrowSVG-sc-1r0p4za-2 QFQTm" viewBox="0 0 10 20" height="15" width="10"><path d="M1 0 L1 19 L 5 15" class="Arrow__ArrowPath-sc-1r0p4za-1 nefsx"></path></svg></span></p> */}

            <div className="row justify-content-center">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-2 col-md-6 col-sm-6 col-12 text-center pt--50" key={i}>
                  <div className="service service__style--1">
                    <div className="icon" style={{color: '#ffffff'}}>
                      {val.icon}
                    </div>
                    <div className="content">
                      <h4 className="title">{val.title}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Home;

//
//
// <style>
// p.extra-padding.Hero__Text-okfuhs-5.eMpOcq {
//   -webkit-font-smoothing: antialiased;
//   -webkit-tap-highlight-color: transparent;
//   box-sizing: border-box;
//   color: #fff;
//   cursor: none;
//   font-family: GreycliffCF;
//   font-size: 7pt;
//   font-weight: 700;
//   letter-spacing: .6em;
//   line-height: 26.1px;
//   margin: 0 0 1.45rem;
//   max-width: 500px;
//   padding: 10vh 0 0;
//   text-transform: uppercase;
//   user-select: none;
//   word-wrap: break-word;
// }
//
// p.extra-padding.Hero__Text-okfuhs-5.eMpOcq:last-child {
//   margin-bottom: 0;
// }
// </style>

{/* <span >Design-led technology studio.</span>

<style>
span {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: #fff;
  cursor: none;
  font-family: GreycliffCF;
  font-size: 153.6px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 144px;
  user-select: none;
  word-wrap: break-word;
}
</style>


<style>
p.extra-padding.Hero__Text-okfuhs-5.eMpOcq {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: #fff;
  cursor: none;
  font-family: GreycliffCF;
  font-size: 7pt;
  font-weight: 700;
  letter-spacing: .6em;
  line-height: 26.1px;
  margin: 0 0 1.45rem;
  max-width: 500px;
  padding: 10vh 0 0;
  text-transform: uppercase;
  user-select: none;
  word-wrap: break-word;
}

p.extra-padding.Hero__Text-okfuhs-5.eMpOcq:last-child {
  margin-bottom: 0;
}
</style> */}
