import React, { Fragment, useState, useEffect  } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
// import ParticlesMain from './ParticlesMain';
import fullpageOptions from "./fullpageConfig";
import Helmet from "./../../component/common/Helmet";
import { NavBar } from './../Nav/NavBar';
import { IconNav } from './../Logo/icon';
import FooterTwo from "./../../component/footer/FooterTwo";
import Loading from './../Loader/loading';

function AnimusWrapper(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
  setTimeout(() => setLoading(false), 4000)
}, [])
return (
  <>
  {loading === false ? (
<Fragment>
  <Helmet pageTitle={`${props.helmetTitle}`} />
      <NavBar />
  <div className="scroll_page fade-in">
  <ReactFullpage
    {...fullpageOptions}
    render={({ state, fullpageApi }) => {
      return (
        <React.Fragment>
          <div data-black-overlay="6">
          <IconNav />
          {props.children}
          <FooterTwo />
          </div>
        </React.Fragment>
      );
    }}
  />
  <div
    className="section_bg"
    style={{
      backgroundImage: `url(${props.backgroundImage})`,
      backgroundSize: 'cover',
      position: "fixed",
      height: "100vh",
      width: "100vw",
      backgroundRepeat: 'no-repeat',
      top: "0",
      left: "0",
      zIndex: "-10",
      transition: '4s',
    }}
  >
  </div>
</div>
</Fragment>
) : (
<Loading />
)}
</>
);
}

export default AnimusWrapper;
