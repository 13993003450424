import React, { Component } from "react";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import About from "./About";

class AboutPage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/about.webp'} helmetTitle={'About Us'}>
      <About />
      </AnimusWrapper>
    );
  }
}

export default AboutPage;
