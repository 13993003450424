/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Stream } from "@cloudflare/stream-react";
import { FaChevronCircleRight, FaChevronDown, FaChevronUp, FaChevronCircleLeft } from "react-icons/fa";
import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow";
import fullpageOptions from "./fullpageConfig";
import Helmet from "./../../component/common/Helmet";
import { NavBar } from './../Nav/NavBar';
import { IconNav } from './../Logo/icon';
import { Footer, FooterDark } from './footer';
import styled from "styled-components";

import GroundReflection from './GroundReflection';
// import Statues from './Statues';

const PlayerStyled = styled(Stream)`
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; height: auto;
`;


const VisualEffectsPage = () => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
  const audioEl1 = document.getElementsByClassName("audio-element-1")[0]
  audioEl1.play()
  // const audioEl2 = document.getElementsByClassName("audio-element-2")[0]
  // audioEl2.play()
  });

  return (
    <Fragment>
      <Helmet pageTitle={'Visual Effects'} />
      <audio className="audio-element-1" data-autoplay loop>
        <source src="/music.m4a" type="audio/mp4" />
      </audio>
          <NavBar />
    <ReactFullpage
      {...fullpageOptions}
      afterLoad={(section, destination, direction) => {
        if (
          destination.item.classList.contains("fp-responsive-auto-height") ||
          destination.isLast
        ) {
          setIsActive(false);
          return;
        } else {
          setIsActive(true);
        }
      }}
      render={({ state, fullpageApi }) => {
        if (!isActive) {
          fullpageApi.setAllowScrolling(false);
        } else {
          if (state.initialized) {
            fullpageApi.setAllowScrolling(false);
          }
        }
        return (
          <React.Fragment>
            <IconNav />
            <div className="d-none d-md-block">

              {/* Start Audi Section */}
              <div className="section fp-section fp-tabble slider_container">
                <div className="slider_content">
                  <audio className="audio-element-2" data-autoplay loop>
                    <source src="/audi_rs6.m4a" type="audio/mp4" />
                  </audio>
                  <GroundReflection />
                  <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-down">
                  <button
                    className="scrolling-button"
                    onClick={() => fullpageApi.moveTo(2)}>
                    <FaChevronDown />
                  </button>
                </div>
                <Footer date={"May"} year={"2021"} title={"VFX page header"} description={"A live web canvas with Audi RS4"} />
                </div>
              </div>
              {/* End Audi Section */}

              <div className="ptb--120" style={{background: '#171717', zIndex: '999999999'}}></div>

              {/* Start Beer Section */}
              <div className="videobackground-gradient section fp-section fp-tabble slider_container">
                <div className="slider_content">
                  <audio className="audio-element-3" data-autoplay loop>
                    <source src="/bar.m4a" type="audio/mp4" />
                  </audio>
                  <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-up">
                  <button
                    className="scrolling-button"
                    onClick={() => fullpageApi.moveTo(1)}>
                    <FaChevronUp />
                  </button>
                </div>
                  <div className="video-background d-none d-md-block">
                    <PlayerStyled
                      src={'https://videodelivery.net/3ef3be6ad06d3ad5b576d4afbba561c1'}
                      autoplay={true}
                      controls={false}
                      // height={'100%'}
                      // width={'100%'}
                      muted={true}
                      loop={true}
                    />
                  </div>
                  <div className="">
                  <div className=" row d-flex flex-wrap">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mt_md--30 mt_sm--30 vfx-call-out">
                        <div>
                          <h2 className="home-copy__title.j-fadeslidein-scrollout-trigger">Visual Effects</h2>
                        </div>
                        <p className="home-copy__txt j-fadeslidein-scrollout-trigger">We design, build, and craft creative sequences, cinematics and training simulations for a broad range of customers, uses and budgets.</p>
                        <ul style={{listStyle: 'none', paddingLeft: '50px'}}>
                          <li><FaChevronCircleRight style={{paddingBottom: '2px'}}/> Visual Effects</li>
                          <li><FaChevronCircleRight style={{paddingBottom: '2px'}}/> 3D Animation</li>
                          <li><FaChevronCircleRight style={{paddingBottom: '2px'}}/> 3D Modelling & Simulation</li>
                          <li><FaChevronCircleRight style={{paddingBottom: '2px'}}/> Product Visualisation</li>
                        </ul>
                        <div className="col-lg-12 align-items-center text-left">
                        <a className="btn-view" href="/contact">Get in touch</a>
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-md-none d-sm-block">
                      <div className="mt_sm--50 align-middle">
                        <PlayerStyled
                          src={'https://videodelivery.net/3ef3be6ad06d3ad5b576d4afbba561c1'}
                          autoplay={true}
                          controls={false}
                          // height={'100%'}
                          // width={'100%'}
                          muted={true}
                          loop={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-down">
                    <button
                      className="scrolling-button"
                      onClick={() => fullpageApi.moveTo(3)}>
                      <FaChevronDown />
                    </button>
                </div>
                <Footer date={"May"} year={"2021"} title={"Gorilla Brew"} description={"CGI Beer commercial, made in Blender"} />
                </div>
              </div>
              {/* End Beer Section */}

              <div className="ptb--120" style={{background: '#171717', zIndex: '999999999'}}></div>

              {/* Start Motion Graphics Section */}
              <div className="videobackground-gradient section fp-section fp-tabble slider_container">
                <div className="slider_content">
                  <audio className="audio-element-3" data-autoplay loop>
                    <source src="/clap.mp3" type="audio/mp3" />
                  </audio>
                  <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-up">
                  <button
                    className="scrolling-button"
                    onClick={() => fullpageApi.moveTo(2)}>
                    <FaChevronUp />
                  </button>
                </div>
                  <div className="video-background-dark d-none d-md-block">
                    <PlayerStyled
                      src={'https://videodelivery.net/0e383c40aeb76ebceab89f8cafbe386c'}
                      autoplay={true}
                      controls={false}
                      // height={'100%'}
                      // width={'100%'}
                      muted={true}
                      loop={true}
                    />
                  </div>
                  <div className="row d-flex flex-wrap">
                    <div className="d-flex col-lg-6 col-sm-12 d-md-none d-sm-block order-2 order-lg-1 align-items-center">
                      <div className="mt_sm--50 align-middle">
                        <PlayerStyled
                          src={'https://videodelivery.net/0e383c40aeb76ebceab89f8cafbe386c'}
                          autoplay={true}
                          controls={false}
                          // height={'100%'}
                          // width={'100%'}
                          muted={true}
                          loop={true}
                        />
                      </div>
                    </div>
                      <div className="d-none d-lg-block mt_md--30 mt_sm--30 vfx-call-out-right text-right col-lg-4 col-md-6 col-sm-12 offset-md-6 offset-lg-7">
                        <div>
                          <h2 className="home-copy__title.j-fadeslidein-scrollout-trigger">Motion Graphics</h2>
                        </div>
                        <p className="home-copy__txt j-fadeslidein-scrollout-trigger">Motion graphics can bring your website or application to life, communicate your message or intentions perfectly or help your customers understand who you are and why you're different.</p>
                        <ul style={{listStyle: 'none'}}>
                          <li>Explainer videos <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                          <li>Corporate Presentations <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                          <li>Media infographics <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                        </ul>
                        <div className="col-lg-12 align-items-center text-right">
                        <a className="btn-view" href="/contact">Get in touch</a>
                      </div>
                    </div>
                    <div className="d-lg-none d-md-block mt_md--30 mt_sm--30 vfx-call-out col-lg-4 col-md-6 col-sm-12">
                      <div>
                        <h2 className="home-copy__title.j-fadeslidein-scrollout-trigger">Motion Graphics</h2>
                      </div>
                      <p className="home-copy__txt j-fadeslidein-scrollout-trigger">Motion graphics can bring your website or application to life, communicate your message or intentions perfectly or help your customers understand who you are and why you're different.</p>
                      <ul style={{listStyle: 'none'}}>
                        <li>Explainer videos <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                        <li>Corporate Presentations <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                        <li>Media infographics <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                      </ul>
                      <div className="col-lg-12 align-items-center text-right">
                      <a className="btn-view" href="/contact">Get in touch</a>
                    </div>
                  </div>

                </div>
                <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-down">
                  <button
                    className="scrolling-button"
                    onClick={() => fullpageApi.moveTo(4)}>
                    <FaChevronDown />
                  </button>
              </div>
              <FooterDark date={"May"} year={"2021"} title={"Motion Graphics Showcase"} description={"A showcase of mograph options. Made in Adobe After Effects"} />
                </div>
              </div>
              {/* End Motion Graphics Section */}

              <div className="ptb--120" style={{background: '#171717', zIndex: '999999999'}}></div>

              {/* Start JellyFish Section */}
              <div className="videobackground-gradient section fp-section fp-tabble slider_container">
                <div className="slider_content">
                  <audio className="audio-element-3" data-autoplay loop>
                    <source src="/water.m4a" type="audio/mp4" />
                  </audio>
                  <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-up">
                  <button
                    className="scrolling-button"
                    onClick={() => fullpageApi.moveTo(3)}>
                    <FaChevronUp />
                  </button>
                </div>
                  <div className="video-background d-none d-md-block">
                    <PlayerStyled
                      src={'https://videodelivery.net/c5c67d97ad7fbc5ee85910c50ac6df79'}
                      autoplay={true}
                      controls={false}
                      // height={'100%'}
                      // width={'100%'}
                      muted={true}
                      loop={true}
                    />
                  </div>
                  <div className=" row d-flex flex-wrap align-items-center">
                    <div className="mt_md--30 mt_sm--30 vfx-call-out">
                      <div>
                        <h2 className="home-copy__title.j-fadeslidein-scrollout-trigger">3D Modelling</h2>
                      </div>
                      <p className="home-copy__txt j-fadeslidein-scrollout-trigger">Products, ideas, characters and even you, brought to life.</p>
                      <div className="col-lg-12 align-items-center text-left">
                      <a className="btn-view" href="/contact">Get in touch</a>
                    </div>
                  </div>
                    <div className="col-lg-6 col-sm-12 d-md-none d-sm-block">
                      <div className="mt_sm--50 align-middle">
                        <PlayerStyled
                          src={'https://videodelivery.net/c5c67d97ad7fbc5ee85910c50ac6df79'}
                          autoplay={true}
                          controls={false}
                          // height={'100%'}
                          // width={'100%'}
                          muted={true}
                          loop={true}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-down">
                    <button
                      className="scrolling-button"
                      onClick={() => fullpageApi.moveTo(5)}>
                      <FaChevronDown />
                    </button>
                </div> */}
                <Footer date={"May"} year={"2021"} title={"Jellyfish with Floating Title"} description={"Animated JellyFish made in Blender"} />
                </div>
              </div>
              {/* End JellyFish Section */}

              <div className="ptb--120" style={{background: '#171717', zIndex: '999999999'}}></div>

              {/* Start Statue Section */}
              {/* <div className="videobackground-gradient section fp-section fp-tabble slider_container">
                <div className="slider_content">
                  <audio className="audio-element-3" data-autoplay loop>
                    <source src="/clap.mp3" type="audio/mp4" type="audio/mp3" />
                  </audio>
                  <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-up">
                  <button
                    className="scrolling-button"
                    onClick={() => fullpageApi.moveTo(4)}>
                    <FaChevronUp />
                  </button>
                </div>
                  <div className="container">
                  <div className=" row d-flex flex-wrap align-items-center">
                    <div className="d-flex col-lg-6 col-sm-12 d-md-none d-sm-block order-2 order-lg-1">
                      <Statues />
                    </div>
                    <div className="d-flex col-lg-4 col-sm-12 order-1 order-lg-2 offset-lg-8 text-right">
                      <div className="details mt_md--30 mt_sm--30" style={{borderRadius: '10px'}}>
                        <div className="section-title">
                          <h2 style={{color: '#ffffff'}} className="title">Motion Graphics</h2>
                        </div>
                        <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}></p>
                        <ul style={{listStyle: 'none', paddingRight: '50px'}}>
                          <li>Explainer videos <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                          <li>Corporate Presentations <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                          <li>Media infographics <FaChevronCircleLeft style={{paddingBottom: '2px'}}/></li>
                        </ul>
                        <div className="col-lg-12 align-items-center text-right">
                        <Link className="rn-button-style--2 rn-btn-light" to="/contact">Get in touch</Link>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div> */}
              {/* End Statue Section */}


              {/* Start Statue Section */}
              {/* <div className="videobackground-gradient section fp-section fp-tabble slider_container">
                <div className="slider_content">
                  <audio className="audio-element-3" data-autoplay loop>
                    <source src="/clap.mp3" type="audio/mp4" type="audio/mp3" />
                  </audio>
                  <div className="row d-flex flex-wrap align-items-center text-center fullscreen-button-up">
                  <button
                    className="scrolling-button"
                    onClick={() => fullpageApi.moveTo(4)}>
                    <FaChevronUp />
                  </button>
                </div>
                <Footer date={"May"} year={"2021"} title={"Web Canvas Lighting"} description={"A live web canvas where user interaction animates field of view and lighting"} />
              </div>
            </div> */}
        {/* End Statue Section */}




        </div>
      </React.Fragment>
    );
  }}
/>
</Fragment>
);
};


export default VisualEffectsPage;
