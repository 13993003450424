import React, { Component } from "react";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import SoftwareProduct from "./SoftwareProduct";

class SoftwareProductPage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/software-product.webp'} helmetTitle={'Software Product'}>
      <SoftwareProduct />
      </AnimusWrapper>
    );
  }
}

export default SoftwareProductPage;
