import React, { Component } from "react";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import Prototyping from "./Prototyping";

class PrototypingPage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/prototyping.webp'} helmetTitle={'Prototyping, R&D'}>
      <Prototyping />
      </AnimusWrapper>
    );
  }
}

export default PrototypingPage;
