import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};

const Logo = {
    height: '150px',
    width: '150px',
    position: 'absolute',
    top: '-60%',
    overflow: 'visible',
    stroke: '#fff',
    strokeWidth: 1.5,
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    zIndex: 9999999999
}


export const Iconphone = () => (
  <div style={Logo}>
    <Link to="/">
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 463.58 104.3"
      className="item"
    >
      <motion.path
        d="M109.48,126.52H90.86L66.16,67.21,41.32,126.52H22.68L66.21,22.61Z"
        transform="translate(-22.68 -22.61)"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 1, ease: [1, 0, 0.8, 1] }
        }}
      />
      <motion.path
        d="M200.19,23.4V126.91l-64.48-63v62.64H118.52V23l64.48,63V23.4Z"
        transform="translate(-22.68 -22.61)"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.2,
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 1, ease: [1, 0, 0.8, 1] }
        }}
      />
      <motion.path
        d="M209.12,126.52V23.4h17.19V126.52Z"
        transform="translate(-22.68 -22.61)"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 1, ease: [1, 0, 0.8, 1] }
        }}
      />
      <motion.path
        d="M329.92,23.39V126.52H312.73V80.75l-30.08,45.68L252.56,80.75v45.77H235.37V23.39l47.28,71.78Z"
        transform="translate(-22.68 -22.61)"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.6,
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 1, ease: [1, 0, 0.8, 1] }
        }}
      />
      <motion.path
        d="M415.16,23.4V88.47a38.15,38.15,0,0,1-76.29,0V23.4h17.19V88.47a21,21,0,0,0,41.91,0V23.4Z"
        transform="translate(-22.68 -22.61)"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.8,
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 1, ease: [1, 0, 0.8, 1] }
        }}
      />
      <motion.path
        d="M486.25,96.89v.49a28.77,28.77,0,0,1-28.74,28.75H453a28.67,28.67,0,0,1-28.32-23.8l16.94-2.94A11.52,11.52,0,0,0,453,108.94h4.54a11.56,11.56,0,0,0,11.55-11.56v-.49a11.58,11.58,0,0,0-7.49-10.81l-18.7-7A28.75,28.75,0,0,1,453,23.4h4.54a28.58,28.58,0,0,1,25.8,16.06l-15.42,7.59a11.49,11.49,0,0,0-10.38-6.46H453A11.55,11.55,0,0,0,448.91,63l18.7,7A28.86,28.86,0,0,1,486.25,96.89Z"
        transform="translate(-22.68 -22.61)"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 1,
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 1, ease: [1, 0, 0.8, 1] }
        }}
      />
    </motion.svg>
    </Link>
  </div>
);
