import React, { Component } from "react";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import Contact from "./Contact";

class ContactPage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/contact.webp'} helmetTitle={'Contact Us'}>
      <Contact />
      </AnimusWrapper>
    );
  }
}

export default ContactPage;
