import React from "react";
import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -1000 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const numColors = 4 * 4
const makeColor = (hue) => `hsl(${hue}, 100%, 60%)`
const colors = Array.from(Array(numColors)).map((_, i) =>
    makeColor(Math.round((360 / numColors) * i))
)

export const MenuItem = ({ i, link, icon, label }) => {
  const style = { backgroundColor: `${colors[i]}`, color: '#080808', fontWeight: '600' };
  return (
    <a href={link}>
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="icon-placeholder align-middle" style={style}>{icon}</div>
      <div className="text-placeholder align-middle" style={style}>{label}</div>
    </motion.li>
  </a>
);
};

export const MenuItemClosed = ({ i, link, icon, label }) => {
  const style = { backgroundColor: `${colors[i]}`, color: '#080808', fontWeight: '600'};
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="icon-placeholder align-middle" style={style}>{icon}</div>
      <div className="text-placeholder align-middle" style={style}>{label}</div>
    </motion.li>
  );
};
