// export default {
//   callbacks: ["onLeave"],
//   navigation: false,
//   scrollOverflow: false,
//   controlArrows: true,
//   verticalCentered: true,
//   showActiveTooltip: true,
//   autoScrolling: true,
//   fitToSection: true,
//   responsiveSlides: true,
//   responsiveWidth: 767,
//   verticalCentered: true,
//   touchSensitivity: 15,
//   dragAndMove: "fingersonly",
//   resetSliders: true,
//   scrollOverflow: true,
//   scrollOverflowReset: true,
//   licenseKey: '19752CE7-7B55496E-9C34963A-39D33105',
// };

export default {
  callbacks: ["onLeave"],
  navigation: false,
  scrollOverflow: false,
  arrowNavigation: false,
  autoScrolling: true,
  fitToSection: true,
  responsiveSlides: false,
  responsiveWidth: 767,
  verticalCentered: true,
  touchSensitivity: 15,
  dragAndMove: "fingersonly",
  licenseKey: '19752CE7-7B55496E-9C34963A-39D33105',
};
