import React from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { CSSProperties, useState } from "react";
import { FaRegWindowRestore, FaMobileAlt, FaMagic  } from "react-icons/fa";

const SolutionsList = [
  {
    id: 1,
    icon: <FaRegWindowRestore />,
    title: 'Technology Agnostic',
    description: 'Animus is a technology-agnostic company. We don\'t sell or promote specific technologies, we make our own using programming languages so we\'re 100% focused on our customers requirements. We pick the right stack for your mission and goals.',
    color: 'RGB(244, 14, 2)',
  },
  {
    id: 2,
    icon: <FaMobileAlt />,
    title: 'Deployment Flexible',
    description: 'Cloud, on premesis, private network? We develop for your environment. We ensure that our applications are containerised, meaning that you can make them run wherever you like. That means your software can grow and change with your business, not the other way around.',
    color: 'RGB(128, 255, 4)'
  },
  {
    id: 3,
    icon: <FaMagic />,
    title: 'IP Ownership',
    description: 'We can build software on your behalf. When we\'ve finished it\'s your intellectual property to continue development, use within your business or to add to your product line.',
    color: 'RGB(84, 254, 255)'
  }
]

function Gallery({ items, setIndex }) {
    return (
        <ul style={container}>
            {items.map((color, i) => (
                <motion.li
                    key={color}
                    onClick={() => setIndex(i)}
                    initial={{ borderRadius: 10 }}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.8 }}
                    style={{ ...item, backgroundColor: `${color.color}`, backgroundImage: `${color.icon}` }}
                    layoutId={color}
                    //transition={{ duration: 5 }}
                    id={i === 0 && "list-red"}
                >
                    <motion.div style={{...child}} layoutId={`child-${color.id}`}>
                      <motion.h3 style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '2em', color: '#eeeeee'}}>{color.icon}</motion.h3>
                    </motion.div>
                </motion.li>
            ))}
        </ul>
    )
}

function SingleImage({ color, setIndex }) {
    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={overlay}
                id="overlay"
                onClick={() => setIndex(false)}
            />
            <div style={singleImageContainer}>
                <motion.div
                    id="color"
                    layoutId={color}
                    initial={{ borderRadius: 20 }}
                    style={{
                      ...singleImage

                    }}
                >
                    <motion.div style={{padding: '5px', paddingBottom: '10px'}}>
                    <motion.div style={{...childOpen}} id="child" layoutId={`child-${color.id}`}>
                      <motion.h2 style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '2em', color: '#eeeeee'}}>{color.icon}</motion.h2>
                    </motion.div>
                    <motion.h5 style={{textAlign: 'center', verticalAlign: 'middle', paddingTop: '10px', color: '#eeeeee', fontSize: '1.5em'}}>{color.title}</motion.h5>
                    <motion.p style={{textAlign: 'left', verticalAlign: 'middle', padding: '5px', marginBottom: '10px', color: '#eeeeee', fontSize: '1.2em', lineHeight: '1.5em'}}>{color.description}</motion.p>
                    </motion.div>
                </motion.div>
            </div>
        </>
    )
}

export function Component() {
    const [index, setIndex] = useState(false)
    return (
        <>
            <Gallery items={solutions} setIndex={setIndex} />
            <AnimatePresence>
                {index !== false && (
                    <SingleImage color={solutions[index]} setIndex={setIndex} />
                )}
            </AnimatePresence>
        </>
    )
}

export function App() {
    return (
        <AnimateSharedLayout type="crossfade">
            <Component />
        </AnimateSharedLayout>
    )
}

const numColors = 4
const makeColor = (hue) => `hsl(${hue}, 100%, 50%)`
const colors = Array.from(Array(numColors)).map((_, i) =>
    makeColor(Math.round((360 / numColors) * i))
)

const solutions = SolutionsList.map(x => x);

const container = {
    // backgroundColor: "#eeeeee",
    backgroundColor: "rgba(0,0,0,0.0)",
    borderRadius: "25px",
    width: "100%",
    height: "auto",
    margin: "0",
    padding: "0 20px 20px 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "space-between",
    listStyle: "none",
}

const item = {
    padding: "20px",
    cursor: "pointer",
    margin: "20px 0 0 20px",
    flex: "1 1 90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: '10px 25px 10px rgba(0, 0, 0, 0.5)',
}

const overlay = {
    position: "absolute",
    height: '100vh',
    width: '100vw',
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    opacity: 1,
}

const singleImageContainer: CSSProperties = {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
}

const singleImage = {
    width: "500px",
    height: "400px",
    padding: 20,
    paddingBottom: '30px',
    borderRadius: 25,
    zIndex: 9999990999999,
    // backgroundColor: '#ffffff',
    color: '#ffffff',
    backgroundImage: 'linear-gradient(to right, rgba(14,67,107,1) , rgba(94,18,97,1))',
    boxShadow: '10px 25px 10px rgba(0, 0, 0, 0.9)',
}

const child = {
    width: 50,
    height: 50,
    borderRadius: 25,
    color: '#eeeeee',
    backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0.5) , rgba(94,18,97,0.5))',
    opacity: 1,
}
const childOpen = {
    backgroundImage: 'linear-gradient(to right, rgba(255,255,255,1) , rgba(33, 150, 243, 1))',
    width: 50,
    height: 50,
    borderRadius: 50,
    opacity: 1,
}

function Magic() {
    return (
        <AnimateSharedLayout className="col-lg-12" type="crossfade">
            <Component />
        </AnimateSharedLayout>
    )
}

export default Magic;
