import React from "react";
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import VideoCTA from "./VideoCTA";
import { Iconphone } from './../Logo/iconphone';

function About() {
  let title = '𝝠nimus',
  description = 'Our unique approach combines the nessessity to create art, the needs of our customers and interacting with the world like human beings. The technology is excellent as are our relationships. We appreciate that we\'re not everyone\'s flavour but we don\'t take that as a risk or a requirement for change. You will choose your art and it\'s a matter of taste';

  return(
    <React.Fragment>
      <div className="section fp-section fp-tabble slider_container">
          <div
            className="slider_content"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.1)'
            }}>
            <div className="container h-100 d-flex justify-content-center align-items-center">
          <VideoCTA />
        </div>
        </div>
      </div>

      <div className="section fp-section fp-tabble slider_container pb_sm--200">
        <div className="about-wrapper">
          <div className="container">
              <div className="row row--35 align-items-center">

                <div className="col-lg-4 col-md-12">
                    <div className="iphone d-none d-lg-block">
                      <div class="iphonex">
                        <div class="front">
                          <div class="screen">
                            <div class="screen__view">
                              <div class="animusLogo"><span></span><button className="rn-button-style--2 rn-btn-light heartbeat">login</button></div>
                              <div class="hello row justify-content-center">
                                <Iconphone />
                                <p>Welcome to <span style={{color: '#ffffff', fontFamily: 'Beckman'}}>𝝠nimus</span></p>
                                <div id="input" className="col-10">
                                <input

                                  type="text"
                                  name="emailAddress"
                                  placeholder="Email Address"
                                />
                                <input

                                  type="password"
                                  name="passWord"
                                  placeholder="Password"
                                />
                              </div>
                              </div>
                            </div>
                            <div class="screen__front">
                              <div class="screen__front-speaker"></div>
                              <div class="screen__front-camera"></div>
                            </div>
                          </div>
                          <div class="front__line"></div>
                          <div class="front__line front__line-second"></div>
                        </div>
                        <div class="phoneButtons phoneButtons-right"></div>
                        <div class="phoneButtons phoneButtons-left"></div>
                        <div class="phoneButtons phoneButtons-left2"></div>
                        <div class="phoneButtons phoneButtons-left3"></div>
                        <div class="back"></div>
                      </div>
                    </div>
                </div>



                <div className="col-lg-8 col-md-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h1 style={{color: '#ffffff', fontFamily: 'Beckman'}} className="title">{title}</h1>
                    </div>
                    <p style={{color: '#d7d7d7', fontSize: '1.2em', lineHeight: '1.5em'}} className="description">{description}</p>
                    {/* <p style={{color: '#d7d7d7', fontSize: '1.2em', lineHeight: '1.5em'}} className="description">{description2}</p> */}
                    <div className="row mt--30 mt_sm--10">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">

                        <div className="about-us-list section-title">
                          <h3 style={{color: '#ffffff', textAlign: 'left'}} className="title"><strong>Data.</strong></h3>
                          <p style={{color: '#d7d7d7', textAlign: 'left'}} >Our customers have data collected in multiple types and formats and require us to collect and ingest additional data to enrich the collection or to source new data altogether.</p>
                        </div>

                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">

                        <div className="about-us-list section-title">
                          <h3 style={{color: '#ffffff'}} className="title"><strong>Developed.</strong></h3>
                          <p style={{color: '#d7d7d7'}}>Working with our customer's requirements we build a full stack application that can scale with their data, developed with security, performance and useability as primary drivers.</p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="text-left mt--30 main-buttons">
                    <Link className="btn-view" to="/about">Who are we?</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  )
}
export default About;
