import React, { Component } from "react";
import Slide from 'react-reveal/Slide';

class Prototyping extends Component{
  render(){
    return(
      <React.Fragment>
        {/* <div className="section fp-section fp-tabble">
          <div className="slider_content">
              <div className="container">
                <div className="row d-flex flex-wrap align-items-center justify-content-center">
                    <div className="col-lg-6 col-sm-12 align-middle section-title">
                      <h2 style={{color: '#ffffff'}} className="text-center text-lg-right text-md-right">Prototyping, Research & Development</h2>
                    </div>
                    <div className="col-lg-6 col-sm-12 align-middle">
                        <img src="https://animusdevelopment.b-cdn.net/images/prototyping/r&d-head-image.png" className="w-100" alt="Custom Web App"/>
                    </div>
                </div>
              </div>
            </div>
          </div> */}
            {/* End Breadcrump Area */}
            <div className="section slider_container fp-section fp-tabble" data-black-overlay="7">
              <div className="slider_content">
                <div className="container">
                  <div className="row d-flex flex-wrap align-items-center">
                      <div className="col-lg-12 col-sm-12 order-1 order-lg-1">
                        <div className="mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                          <div className="section-title">
                            <h2 style={{color: '#ffffff'}} className="title text-center">The <span style={{fontFamily: 'Beckman'}}>𝝠nimus</span> Discovery Process</h2>
                          </div>
                          <div className="row d-flex flex-wrap align-items-center">
                          <div className="col-lg-6 col-sm-12 order-2 order-lg-2">
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>When you’re kicking off a new and exciting software project for your business, it can be tempting to dive right in. But without proper advance research, planning, and legwork, it’s impossible to know whether your team and the development team are aligned, and you’re likely to run into unpleasant (and expensive) surprises and change orders along the way. That’s where discovery and prototyping come in.</p>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>Before we write a single line of code, our engineers and business analysts work with your team to identify goals, create requirements, outline technical specifications, and build prototypes of the finished product. That way, we can begin development with the confidence that everyone is on the same page, set up for success in bringing your vision to life.</p>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>The discovery process allows us not only to align on goals, but also to create educated, accurate cost estimates and RFP responses, beginning the project with the car and transparency we value at every phase of our relationships with our clients.</p>
                        </div>

                        <div className="col-lg-6 col-sm-12 order-1 order-lg-1 align-items-center mt_md--100 mt_sm--70 mb_sm--70 pt_lg--50">
                          <img className="w-100" src="https://animusdevelopment.b-cdn.net/images/prototyping/prototyping-intro.png" style={{display: 'block'}} alt="Prototyping intro" />
                        </div>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section slider_container fp-section fp-tabble" data-black-overlay="7">
              <div className="slider_content">
                <div className="container">
                  <div className="row d-flex flex-wrap align-items-center">
                      <div className="col-lg-12 col-sm-12 order-1 order-lg-1">
                        <div className="mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                          <div className="row d-flex flex-wrap align-items-center">
                          <div className="col-lg-6 col-sm-12 order-2 order-lg-1">
                            <div className="section-title">
                              <h2 style={{color: '#ffffff'}} className="title">Prototyping</h2>
                            </div>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>Even once your team and ours have worked together to outline the key goals and features of the new software system, it can be difficult for your team to visualise the system come to life — that’s not because you haven’t successfully defined what you need, but because your expertise is your business, not its software.</p>
                          <p style={{color: '#ffffff', fontSize: '1.2em', lineHeight: '1.5em'}}>So, to mitigate the risk of revealing a finished product only to have you discover it isn’t what you wanted, we use prototyping as a gentle step from the realm of abstract requirements into a more concrete picture of how your software will look, feel, and work. The process is like dipping a toe in the water before diving in.</p>
                        </div>

                        <div className="col-lg-6 col-sm-12 order-1 order-lg-2 align-items-center mt_md--100 mt_sm--70 mb_sm--70 pt_lg--50">
                          <img className="w-100" src="https://animusdevelopment.b-cdn.net/images/prototyping/prototyping-2.png" style={{display: 'block'}} alt="Prototyping 2" />
                        </div>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    }
    export default Prototyping;
