import React from "react";
import { a } from "react-spring";
import { Slider } from "./Slider";
import { items } from "./items";
import { Link } from 'react-router-dom';
import VFXParticles from './../VFXParticles';
import styles from "./styles.module.css";

export default function HomeVFX() {
  return (
<div className="section fp-section fp-tabble slider_container pb_sm--200">
    <div className="slider_content container">
      <div className="section-title">
        <h1 className="title">Visual Effects</h1>
      </div>
      <p style={{color: '#d7d7d7', fontSize: '1.2em', lineHeight: '1.5em'}} className="description">We create innovative and creative sequences in mixed mediums for ourselves and our clients.</p>
      <div className="text-left mt--30 main-buttons">
        <Link className="btn-view" to="/visual-effects">Visit the VFX page</Link>
      </div>
    <div className={`flex fill center ${styles.container}`}>
      <div className={styles.main}>
        <Slider items={items} width={700} visible={3}>
          {({ css }, i) => (
            <div className={styles.content}>
              {/* <div className={styles.marker}>{String(i).padStart(2, "0")}</div> */}
              <a.div
                className={styles.image}
                style={{ backgroundImage: css }}
              />
            </div>
          )}
        </Slider>
      </div>
    </div>
  <VFXParticles />
  </div>
</div>
  );
}
