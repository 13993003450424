import React, { Component } from "react";
import AnimusWrapper from "./AnimusWrapper/AnimusWrapper";

 class error404 extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/loading.svg'} helmetTitle={'Technology'}>
      <div className="section fp-section fp-tabble" data-black-overlay="6">
        <div className="slider_content">
            <div className="container">
              <div className="row d-flex flex-wrap align-items-center justify-content-center">
                  <div className="col-lg-12 col-sm-12 align-middle section-title">
                    <h2 style={{color: '#ffffff'}} className="software-title-right text-center"><span style={{fontSize: '2.5em'}}>404!</span><br />The page you were looking for could not be found.</h2>
                  </div>
                  <div className="row pt--50">
                    <a className="rn-button-style--2 btn-solid align-text-center" href="/">Back To Homepage</a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </AnimusWrapper>
    );
  }
}

export default error404;
