import React from "react";
import { Link } from 'react-router-dom';
import { Stream } from "@cloudflare/stream-react";

const FooterTwo = () => {

    const currentYear = new Date().getFullYear();

    return (
      <div className="d-none d-sm-block d-md-none">
      <div className="section fp-section fp-tabble slider_content slider-video-bg">
        <div className="video-background">
        <div id="video-footer">
          <Stream
            src={'https://videodelivery.net/c433283a51410bbb7928c6db194b95f6'}
            autoplay={true}
            controls={false}
            // height={'100%'}
            // width={'100%'}
            muted={true}
            loop={true}
          />
        </div>
      </div>
        <div className="footer-image"></div>
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src="https://animusdevelopment.b-cdn.net/images/logo/animus_logo_white.png" alt="logo" />
                            </a>
                            <p>𝝠nimus is a digital creative agency that provides design, project management, web and mobile development, visual effects, and support services.</p>

                            <ul className="social-link d-none">
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6 d-none d-md-block">
                        <div className="single-footer-widget pl-5 pl_sm-0">
                            <h3>Explore</h3>

                            <ul className="footer-links-list">
                              <li><Link to="/custom-software">Custom Software</Link></li>
                              <li><Link to="/software-product">Software Product</Link></li>
                              <li><Link to="/prototyping">Prototyping, R&D</Link></li>
                              <li><Link to="/data-collection">Data Collection</Link></li>
                              <li><Link to="/visual-effects">Visual Effects</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6 d-none d-md-block">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/about">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/technology">
                                        Technology
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms">
                                        Terms & Privacy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 d-none d-md-block">
                        <div className="single-footer-widget">
                            <h3>Contact</h3>

                            <ul className="footer-links-list">
                                <li>
                                    71-75 Shelton Street, London<br />WC2H 9JQ
                                </li>
                                <li>
                                    <a href="tel:+44 33 3335 5671">+44 33 3335 5671</a>
                                </li>
                                <li>
                                    <a href="mailto:info@animus.email">info@animus.email</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                            <p>Copyright <span aria-label="copyright" role="img">©</span> {currentYear} Animus Bytes Ltd, company number: 13410090, all rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
      </div>
      </div>
    );
}

export default FooterTwo;
