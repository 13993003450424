import React, { Component } from "react";
import { FaChevronCircleDown, FaChevronCircleUp  } from "react-icons/fa";
import ReactCardCarousel from "react-card-carousel";

const BackList = [
  {
    icon: 'https://animusdevelopment.b-cdn.net/images/tech/express.png',
    link: '/software-product',
    title: 'Express',
    description: 'Express is a minimal and flexible Node.js web application framework that provides a robust set of features for web and mobile applications.'
  },
  {
    icon: 'https://animusdevelopment.b-cdn.net/images/tech/node.png',
    link: '/prototyping',
    title: 'Node',
    description: 'Node.js is an open-source, cross-platform, JavaScript runtime environment that executes JavaScript code outside a web browser.'
  },
  {
    icon: 'https://animusdevelopment.b-cdn.net/images/tech/mongo.png',
    link: '/data-collection',
    title: 'Mongo',
    description: 'MongoDB is a cross-platform document-oriented database program. Classified as a NoSQL database program, MongoDB uses JSON-like documents with optional schemas. MongoDB is developed by MongoDB Inc.'
  },
  {
    icon: 'https://animusdevelopment.b-cdn.net/images/tech/python.png',
    link: '/data-collection',
    title: 'Python',
    description: 'Python is an interpreted, high-level and general-purpose programming language. Created by Guido van Rossum and first released in 1991, Python\'s design philosophy emphasises code readability with its notable use of significant whitespace.'
  },
  {
    icon: 'https://animusdevelopment.b-cdn.net/images/tech/mern.png',
    link: '/data-collection',
    title: 'MERN',
    description: 'MERN is the acronym for MongoDB, Express JS, React JS and Node JS. The MERN stack is a combination of the above technologies, all based on JavaScript, used to build advanced web applications. It is an open source full stack development framework.'
  },
  {
    icon: 'https://animusdevelopment.b-cdn.net/images/tech/rails.png',
    link: '/custom-software',
    title: 'Rails',
    description: 'Rails, is a server-side web application framework written in Ruby. Rails is a model–view–controller framework, providing default structures for a database, a web service, and web pages.'
  }
]

class BackEnd extends Component{
  constructor(props) {
    super(props);
    this.Carousel = React.createRef();
    this.handleClickDown = this.handleClickDown.bind(this);
    this.handleClickUp = this.handleClickUp.bind(this);
  }

  handleClickDown() {
    this.Carousel.current.prev();
  }

  handleClickUp() {
    this.Carousel.current.next();
  }


  render(){
    const {column} = this.props;
    return(
      <React.Fragment>
        <div className="section fp-section fp-tabble" data-black-overlay="7">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 order-1 order-lg-1 pt_sm--100">
                <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                  <h2 className="title">Backend Development</h2>
                  <p>Back-end development is rarely seen but is at the heart of the application and provides the foundation on which all other features and functions are built upon. We can provide robust, scalable, secure and high-performance platforms for your software, web and mobile applications. To ensure that we  deliver an outstanding back-end solutions, our developers have successfully belnded a mix of established, stable development tools and methodologies with cutting-edge technology. This blend ensures that we can provide performant, efficient applications that are also robust so that our clients can stay one step ahead of the competition.</p>
                  <div className="tech-arrow row">
                    <div className="col d-flex justify-content-center">
                      <FaChevronCircleUp className="solution-click" style={{fontSize: '3em', cursor: 'pointer', marginRight: '20px'}} onClick={this.handleClickUp} />
                      <FaChevronCircleDown className="solution-click" style={{fontSize: '3em', cursor: 'pointer', marginLeft: '20px'}} onClick={this.handleClickDown} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 mt_md--50 order-2 order-lg-2">
                <div className="tech-services">
                  <ReactCardCarousel spread={"narrow"} autoplay={true} autoplay_speed={5000} alignment={"vertical"} disable_box_shadow disable_keydown={true} ref={ this.Carousel }>
                    {BackList.map( (val , i) => (
                      <div className={`${column}`} key={i}>
                        <div className="service tech-cards--1 tech-cards">
                          <div className="icon d-flex justify-content-center">
                            <img src={val.icon} alt="Tech" />
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p>{val.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ReactCardCarousel>
                </div>
              </div>
            </div>

          </div>
        </div>

      </React.Fragment>
    )
  }
}
export default BackEnd;
