import React from "react";
import { motion } from "framer-motion";
import { MenuItem, MenuItemClosed } from "./MenuItem";
import { FaLocationArrow, FaDiceD6, FaRocket, FaTabletAlt, FaTh, FaUserFriends, FaRegSun, FaRegListAlt, FaRegFileAlt, FaRegIdCard, FaGamepad } from "react-icons/fa";

const variants = {
 open: {
   zIndex: 9999999999,
   transition: { staggerChildren: 0.07, delayChildren: 0.2 }
 },
 closed: {
   zIndex: 0,
   transition: { staggerChildren: 0.05, staggerDirection: -1 }
 }
};

const MenuData = [
  {
    label: "Home",
    link: "/",
    icon: <FaLocationArrow style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Custom Software",
    link: "/custom-software",
    icon: <FaDiceD6 style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Software Product",
    link: "/software-product",
    icon: <FaTabletAlt style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Prototyping, R&D",
    link: "/prototyping",
    icon: <FaRocket style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Data Collection",
    link: "/data-collection",
    icon: <FaTh style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Animation & VFX",
    link: "/visual-effects",
    icon: <FaGamepad style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Technology",
    link: "/technology",
    icon: <FaRegSun style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  // {
  //   label: "News",
  //   link: "/news",
  //   icon: <FaRegFileAlt style={{fontSize: '18px', marginBottom: '2px'}} />,
  // },
  {
    label: "About",
    link: "/about",
    icon: <FaUserFriends style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Contact",
    link: "/contact",
    icon: <FaRegIdCard style={{fontSize: '18px', marginBottom: '2px'}} />,
  },
  {
    label: "Terms & Privacy",
    link: "/terms",
    icon: <FaRegListAlt style={{fontSize: '18px', marginBottom: '2px'}} />,
  }
];

export const Navigation = () => (
 <motion.ul variants={variants}>
   {MenuData.map((menu, i) => (
     <MenuItem i={i} key={i} link={menu.link} icon={menu.icon} label={menu.label} />
   ))}
 </motion.ul>
);

export const NavigationClosed = () => (
 <motion.ul variants={variants}>
   {MenuData.map((menu, i) => (
     <MenuItemClosed i={i} key={i} icon={menu.icon} label={menu.label} />
   ))}
 </motion.ul>
);
