import React, { Component } from "react";
import ReactCardCarousel from "react-card-carousel";
import { FaChevronCircleDown, FaChevronCircleUp  } from "react-icons/fa";

const DataList = [
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/tensor.png',
      link: '/custom-software',
      title: 'TensorFlow',
      description: 'TensorFlow is a free and open-source software library for dataflow and differentiable programming across a range of tasks. It is a symbolic math library, and is also used for machine learning applications such as neural networks.'
  },
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/keras.png',
      link: '/software-product',
      title: 'Keras',
      description: 'Keras is an open-source neural-network library written in Python. It is capable of running on top of TensorFlow, Microsoft Cognitive Toolkit, R, Theano, or PlaidML. Designed to enable fast experimentation with deep neural networks, it focuses on being user-friendly, modular, and extensible.'
  },
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/pandas.png',
      link: '/prototyping',
      title: 'pandas',
      description: 'pandas is a software library written for the Python programming language for data manipulation and analysis. In particular, it offers data structures and operations for manipulating numerical tables and time series.'
  },
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/pytorch.png',
      link: '/data-collection',
      title: 'PyTorch',
      description: 'PyTorch is an open source machine learning library based on the Torch library, used for applications such as computer vision and natural language processing, primarily developed by Facebook\'s AI Research lab.'
  }
]

class BigData extends Component{
  constructor(props) {
    super(props);
    this.Carousel = React.createRef();
    this.handleClickDown = this.handleClickDown.bind(this);
    this.handleClickUp = this.handleClickUp.bind(this);
  }

  handleClickDown() {
    this.Carousel.current.prev();
  }

  handleClickUp() {
    this.Carousel.current.next();
  }

  render(){

        return(
            <React.Fragment>
                <div className="section fp-section fp-tabble" data-black-overlay="7">
                    <div className="container">
                      <div className="row">
                          <div className="col-lg-6 col-12 order-1 order-lg-1 pt_sm--100">
                            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                              <h2 className="title text-left">Big Data & Analytics</h2>
                              <p>Big data analytics solutions for leading organisations around the world. We offer complete services to help you harness the immense power that Big Data holds. We have the expertise in big data tools and processes to derive actionable insights from mountains of disparate data that enterprises collect each day. Our specialists have pioneered big data analytics solutions for leading organizations around the world and we offer complete services to help you harness the power of your big data.</p>
                              <div className="tech-arrow row">
                                <div className="col d-flex justify-content-center">
                                  <FaChevronCircleUp className="solution-click" style={{fontSize: '3em', cursor: 'pointer', marginRight: '20px'}} onClick={this.handleClickUp} />
                                  <FaChevronCircleDown className="solution-click" style={{fontSize: '3em', cursor: 'pointer', marginLeft: '20px'}} onClick={this.handleClickDown} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12 mt_md--50  order-2 order-lg-2">
                              <div className="row">
                                <div className="tech-services">
                                <ReactCardCarousel spread={"narrow"} autoplay={true} autoplay_speed={5000} alignment={"vertical"} disable_box_shadow disable_keydown={true} ref={ this.Carousel }>
                                  {DataList.map( (val , i) => (
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                              <div className="service tech-cards--1 tech-cards">
                                                  <div className="icon d-flex justify-content-center">
                                                      <img src={val.icon} alt="Tech" />
                                                  </div>
                                                  <div className="content">
                                                      <h3 className="title">{val.title}</h3>
                                                      <p>{val.description}</p>
                                                  </div>
                                              </div>
                                      </div>
                                  ))}
                                </ReactCardCarousel>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
            </React.Fragment>
        )
}
}
export default BigData;
