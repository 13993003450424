import React from "react";
import tw from "twin.macro";
import MenuCard from "./MenuCard";
import { Link } from 'react-router-dom';

const sections = [
  {
    title: "Custom Software",
    imageUrl: "https://animusdevelopment.b-cdn.net/images/solutions/custom-software-menu.png",
    sectionLink: "/custom-software",
  },
  {
    title: "Software Product",
    imageUrl: "https://animusdevelopment.b-cdn.net/images/solutions/software-product-menu.png",
    sectionLink: "/software-product"
  },
  // {
  //   title: "Prototyping",
  //   imageUrl: "https://animusdevelopment.b-cdn.net/images/solutions/prototyping-menu.png",
  //   sectionLink: "/prototyping",
  // },
  {
    title: "Data Collection",
    imageUrl: "https://animusdevelopment.b-cdn.net/images/solutions/data-collection-menu.png",
    sectionLink: "/data-collection",
  },
  {
    title: "Animation, Modelling & VFX",
    imageUrl: "https://animusdevelopment.b-cdn.net/images/solutions/visual-effects-1.png",
    sectionLink: "/visual-effects",
  }
];

export const Solutions = () => (
  <div className="section fp-section fp-tabble slider_container section_one pb_sm--200 pb_md--200">
    <div className="container slider_content">
      <div className="row">
        <div className="section-title">
          <h2 style={{color: '#ffffff'}} className="title">Solutions</h2>
        </div>
        <p style={{color: '#d7d7d7', fontSize: '1.2em', lineHeight: '1.5em', paddingBottom: '10px'}}>𝝠nimus serves businesses in all major sectors, providing exceptional software solutions for large corporations, mid-sized enterprises, and startups. We use industry-specific knowledge and custom-tailor our products to each customer's unique needs.</p>
      </div>
      <div className="row">
      <div className="main-buttons text-left pb-2">
        <h3 color="light">Have an idea and need help?</h3>
        <div className="text-left">
        <Link className="btn-view" to="/contact">Let's talk</Link>
        </div>
      </div>
    </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div css={tw`max-w-screen-lg p-5 sm:p-10 mx-auto`} >
          <div css={tw`flex flex-row flex-wrap box-border`} className="align-items-center">
            <div className="row flex-row d-flex justify-content-center">
              {sections.map(({ title, imageUrl, sectionLink }, id) => (
                <MenuCard key={id} titleText={title} imageUrl={imageUrl} sectionLink={sectionLink} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
