import React, { useState } from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box } from "./Core";
import ReactPlayer from "react-player";
import { FaChevronCircleRight } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { device } from "./utils";
import Clouds from './Clouds';

const IconButton = styled(Box)`
  cursor: pointer;
  font-size: 100px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #ffffff;
  width: 64px;
  height: 64px;
  @media ${device.sm} {
    width: 100px;
    height: 100px;
  }
  @media ${device.md} {
    width: 124px;
    height: 124px;
  }
`;

const ModalStyled = styled(Modal)`
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    max-width: initial;
    max-height: initial;
    margin: 0;
  }
  .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    padding-top: 15px;
    background: rgba(0, 0, 0, 0.75);
    @media ${device.lg} {
      padding-top: 30px;
    }
  }
`;

const PlayerStyled = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
`;

const DivStyled = styled.div`
  margin: 0 auto;
  width: 100%;
  height: auto;
  visibility: visible;
  @media ${device.md} {
    width: 90%;
    height: 650px;
  }
  opacity: 0;
  visibility: hidden;
  transition: all 2s ease-out;
  &.loaded {
    opacity: 1;
    visibility: visible;
  }
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 0;
  left: 3rem;
  position: absolute;
  height: 5rem;
  width: 5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: #fff;
  @media ${device.md} {
    right: 2rem;
  }
`;

function VideoCTA() {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(true);

const CloseButton = (props) => (
  <CloseWrapper {...props}>
    <Button variant="secondary">
      Close
    </Button>
  </CloseWrapper>
);

  return (
    <>
      {/* <!-- VideoCTA Area --> */}
      <div>
        <div className='container'>
        <div className="row d-flex flex-wrap align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="mt_md--30 mt_sm--30">
              <div className="text-left pb-2">
                <Title color="light">Why us?</Title>
              </div>
              <h1 className="text-left" style={{
                fontFamily: 'adobe-garamond-pro,"Helvetica Neue",Helvetica,Arial,sans-serif',
                fontSize: '3em',
                fontWeight: '700',
                fontStyle: 'italic'
              }}>
              <blockquote /> We're a digital creative agency with some flavour
            </h1>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="mt_sm--50 align-middle">
              <div className="position-relative overflow-hidden align-items-center">
                <img style={{background: 'rgba(108, 122, 137,0.1)', backgroundSize: 'auto', borderRadius: '10px', opacity: '0.7'}} src={`https://videodelivery.net/a76613ae48bd185224926b731d93c363/thumbnails/thumbnail.jpg?time=30s`} alt="Animus Main Video" />
                <IconButton onClick={handleShow}>
                  <FaChevronCircleRight className="solution-click" />
                </IconButton>
              </div>
            </div>
          </div>
        <div className="main-buttons col-lg-6 text-left">
          <p color="light">Like our videos? Head to the VFX Page</p>
          <div className="text-left">
          <Link className="btn-view" to="/visual-effects">Take me there</Link>
          </div>
        </div>
      </div>
      </div>
      <ModalStyled
        size="lg"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="text-center position-relative">
          <CloseButton onClick={handleClose} />
          <div className={`h-100 d-flex align-items-center w-100`}>
            <DivStyled className={`${loading ? "loading" : "loaded"}`}>
              <PlayerStyled
                url={`https://videodelivery.net/a76613ae48bd185224926b731d93c363/manifest/video.m3u8`}
                width="100%"
                height="100%"
                playing={true}
                controls={true}
                config={{
                  file: {
                    attributes: { crossorigin: "anonymous" },
                  }
                }}
                onReady={() => {
                  setLoading(false);
                }}
              />
            </DivStyled>
          </div>
        </Modal.Body>
      </ModalStyled>
      <Clouds />
    </div>
    </>
  );
};

export default VideoCTA;
