import React, { Component } from "react";

class Technology extends Component{
  render(){
        return(
            <React.Fragment>
              <div className="section fp-section fp-tabble" >
                <div className="slider_content">
                    <div className="container">
                      <div className="row d-flex flex-wrap align-items-center justify-content-center">
                          <div className="col-lg-6 col-sm-12 align-middle section-title order-2 order-lg-2">
                            <h2 style={{color: '#ffffff'}} className="text-center text-lg-left text-md-left">Our Technology</h2>
                          </div>
                          <div className="col-lg-6 col-sm-12 align-middle order-1 order-lg-1">
                              <img src="https://animusdevelopment.b-cdn.net/images/tech/technology.png" alt="Our Technology" className="w-100 slide-in-blurred-right" />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </React.Fragment>
        )
}
}
export default Technology;
