import React, { Component } from "react";
// import AnimusHomeWrapper from "./../AnimusHomeWrapper/AnimusHomeWrapper";
import AnimusWrapper from "./../AnimusWrapper/AnimusWrapper";
import About from "./About";
import Home from "./Home";
// import HomeCarousel from './HomeCarousel';
import HomeVFX from './HomeVFX';
import { Solutions } from './Solutions';
// import Services from './Services';
// import NewsArticle from './NewsArticle';
import Process from './Process';
import Tech from './Tech';
import CallToAction from './../CallToAction/CallToAction';

class HomePage extends Component {
  render() {
    return (
    <AnimusWrapper backgroundImage={'https://animusdevelopment.b-cdn.net/images/bg/main-bg.webp'} helmetTitle={'Home'}>
      <Home />
      {/* <HomeCarousel /> */}
      <About />
      <HomeVFX />
      <Process />
      <Solutions />
      <Tech />
      {/* <CallToAction /> */}
      </AnimusWrapper>
    );
  }
}

export default HomePage;
