import React, { Component } from "react";
import ReactCardCarousel from "react-card-carousel";
import { FaChevronCircleDown, FaChevronCircleUp  } from "react-icons/fa";

const Enablers = [
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/aws.png',
      link: '/custom-software',
      title: 'AWS',
      description: 'Amazon Web Services offers reliable, scalable, and inexpensive cloud computing services. Free to join, pay only for what you use.'
  },
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/google.png',
      link: '/prototyping',
      title: 'Google Cloud',
      description: 'Google Cloud is a suite of cloud computing services that runs on the same infrastructure that Google uses internally for its end-user products, such as Google Search, Gmail, file storage, and YouTube.'
  },
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/mongo.png',
      link: '/prototyping',
      title: 'MogoDB Atlas',
      description: 'MongoDB Atlas is the global cloud database service for modern applications. Deploy fully managed MongoDB across AWS, Azure, or GCP.'
  },
  {
      icon: 'https://animusdevelopment.b-cdn.net/images/tech/docker.png',
      link: '/prototyping',
      title: 'Docker',
      description: 'Docker is a set of platform as a service products that use OS-level virtualisation to deliver software in packages called containers.'
  }
]

class Deployment extends Component{
  constructor(props) {
    super(props);
    this.Carousel = React.createRef();
    this.handleClickDown = this.handleClickDown.bind(this);
    this.handleClickUp = this.handleClickUp.bind(this);
  }

  handleClickDown() {
    this.Carousel.current.prev();
  }

  handleClickUp() {
    this.Carousel.current.next();
  }

  render(){

        return(
            <React.Fragment>
                <div className="section fp-section fp-tabble" data-black-overlay="7">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 col-12 mt_md--50  order-2 order-lg-1">
                          <div className="row">
                            <div className="tech-services">
                              <ReactCardCarousel spread={"narrow"} autoplay={true} autoplay_speed={5000} alignment={"vertical"} disable_box_shadow disable_keydown={true} ref={ this.Carousel }>
                                {Enablers.map( (val , i) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                            <div className="service tech-cards--1 tech-cards">
                                                <div className="icon d-flex justify-content-center">
                                                    <img src={val.icon} alt="Tech" />
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                    </div>
                                ))}
                              </ReactCardCarousel>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12 order-1 order-lg-2 pt_sm--100">
                          <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">Deployment</h2>
                            <p>Our clients require their applications to run anywhere. We build Dockerised apps by default, allowing them to be deploying in the cloud, private networks or in standalone environments.</p>
                            <div className="tech-arrow row">
                              <div className="col d-flex justify-content-center">
                                <FaChevronCircleUp className="solution-click" style={{fontSize: '3em', cursor: 'pointer', marginRight: '20px'}} onClick={this.handleClickUp} />
                                <FaChevronCircleDown className="solution-click" style={{fontSize: '3em', cursor: 'pointer', marginLeft: '20px'}} onClick={this.handleClickDown} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </React.Fragment>
        )
}
}
export default Deployment;
