export default {
  callbacks: ["onLeave"],
  navigation: false,
  scrollOverflow: false,
  arrowNavigation: false,
  autoScrolling: true,
  fitToSection: true,
  responsiveSlides: true,
  responsiveWidth: 767,
  verticalCentered: true,
  touchSensitivity: 15,
  dragAndMove: "fingersonly",
  dropEffect: true,
  dropEffectOptions: {
    speed: 2000,
    color: ['#070a0c']
  },
  licenseKey: '19752CE7-7B55496E-9C34963A-39D33105',
  dropEffectKey: ['YW5pbXVzZGV2ZWxvcG1lbnQuY29tX3JuNlpISnZjRVZtWm1WamRBPT1maFc=', 'YW5pbXVzYnl0ZXMuY29tX3I1SVpISnZjRVZtWm1WamRBPT05OFM=']
};
