import React, { Component } from "react";
import ContactForm from './ContactForm';


class Contact extends Component{
  render(){
    return(
      <React.Fragment>
          <div className="section fp-section fp-tabble slider_content">
              <div className="container">
                <ContactForm />
              </div>
          </div>
      </React.Fragment>
        )
      }
    }
    export default Contact;
